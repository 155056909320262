import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Escape from "../../controller/Escape";
import Fetch from "../../controller/Fetch";
import { ConvertToDisplayDate } from "../../controller/keys";
import Loading from "../../controller/Loading";
import UploadForm from "../../controller/UploadForm";
import Mode from "../common/mode";

export default function Report(){
    const {course,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [File,setFile]=useState('notShow');
    const [Submit,setSubmit]=useState('notShow');
    const [SubmitFile,setSubmitFile]=useState();
    const [mode,setMode]=useState();
    const [UploadFile,setUploadFile]=useState();

    const Comment=useRef();
    const Score=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/report/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    }
    function modalFile(e){
        setFile(e.target.dataset.id?Number(e.target.dataset.id):'notShow');
    }
    function modalSubmit(e){
        setSubmit(e.target.dataset.id?Number(e.target.dataset.id):'notShow');
        setSubmitFile();
    }
    function showSubmitFile(e){
        setSubmitFile(Number(e.target.dataset.id));
    }
    function closeModal(){
        setFile('notShow');
        setSubmit('notShow');
    }
    function doSubmit(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/report/grading/${Item['submits'][Submit]['StudentReportId']}`,
            method:'PUT',
            body:{
                comment:Comment.current.value,
                score:Number(Score.current.value)
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["課題を採点しました。"]})
                Initialize();
                closeModal();
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            <Mode setMode={setMode} />
            {Item?<Crumb CurrentText={Item['reports']['ReportTitle']} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${course}`,text:Item['reports']['CourseName']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-3">
                    <h2>
                        {Item['reports']['ReportTitle']}
                    </h2>
                    <Table striped>
                        <tbody>
                            <tr>
                                <th>
                                説明
                                </th>
                                <td>
                                {Escape(Item['reports']['content'])}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    提出期限
                                </th>
                                <td>
                                    {ConvertToDisplayDate(Item['reports']['deadline'],true)}
                                </td>
                            </tr>{mode?"":
                            <tr>
                                <th>公開期間</th>
                                <td>{Item['reports']['PublicDate']?
                                <span>受講登録{Item['reports']['EntriedNumber']}日後</span>:
                                <span>{ConvertToDisplayDate(Item['reports']['FromDate'])}から{ConvertToDisplayDate(Item['reports']['ToDate'])}まで</span>}
                                </td>
                            </tr>}
                            <tr>
                                <th>
                                添付ファイル
                                </th>
                                <td>
                                    <ListGroup>{Item['reports']['files'].map((file,index)=>
                                        <ListGroup.Item action data-id={index} key={index} onClick={modalFile}>
                                            {file['FileName']}
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                得点
                                </th>
                                <td>
                                {Item['reports']['score']}点
                                </td>
                            </tr>
                        </tbody>
                    </Table>{mode?"":
                    <div className="mt-2 text-end">
                        <Link to={`/course/${course}/report/${id}/edit`} className="btn btn-success">編集</Link>
                    </div>}{!isNaN(File)?
                    <Modal size="xl" show={true} onHide={closeModal} centered scrollable>
                        <Modal.Header closeButton>
                            <Modal.Title>{Item['reports']['files'][File]['FileName']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm="6">
                                    <iframe src={Item['reports']['files'][File]['link']} className='iframe-file' />
                                </Col>
                                <Col sm="6">
                                    <ListGroup>{Item['reports']['files'].map((file,index)=>
                                        <ListGroup.Item action onClick={modalFile} key={index} data-id={index}>
                                            {file['FileName']}
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <a href={Item['reports']['files'][File]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>
                        </Modal.Footer>
                    </Modal>:""}
                </div>{mode?
                <div className="my-3">
                    <h3>
                        課題の提出
                    </h3>
                    <Table striped responsive>
                        <tbody>
                            <tr>
                                <th>
                                    添付ファイル
                                </th>
                                <td>
                                    <UploadForm setUploadFile={setUploadFile} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    コメント
                                </th>
                                <td>
                                    <FormControl as="textarea" placeholder="コメント" ref={Comment} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="primary">
                        提出
                    </Button>
                </div>:""}
                <div className="mt-3">
                    <h3>
                        提出済み課題
                    </h3>
                    <ListGroup>{Item['submits'].map((submit,index)=>
                        <ListGroup.Item action onClick={modalSubmit} data-id={index} key={index}>{mode?
                            <>{ConvertToDisplayDate(submit['SubmitDatetime'],true)}&nbsp;提出</>:
                            <>{submit['UserName']}({ConvertToDisplayDate(submit['SubmitDatetime'],true)}&nbsp;提出)</>}
                        </ListGroup.Item>)}
                    </ListGroup>{!isNaN(Submit)?
                    <Modal size="xl" show={true} onHide={closeModal} centered scrollable>
                        <Modal.Header closeButton>
                            <Modal.Title>提出内容詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm="6">
                                    {Item['submits'][Submit]['answers']['files'][SubmitFile]?<iframe src={Item['submits'][Submit]['answers']['files'][SubmitFile]['link']} className='iframe-file' />:""}
                                </Col>
                                <Col sm="6">
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    提出内容
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>{mode?"":
                                            <tr>
                                                <th>
                                                    提出者
                                                </th>
                                                <td>
                                                    {Item['submits'][Submit]['UserName']}
                                                </td>
                                            </tr>}
                                            <tr>
                                                <th>
                                                    提出日時
                                                </th>
                                                <td>
                                                    {ConvertToDisplayDate(Item['submits'][Submit]['SubmitDatetime'],true)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    添付ファイル
                                                </th>
                                                <td>
                                                    <ListGroup>{Item['submits'][Submit]['answers']['files'].map((file,index)=>
                                                        <ListGroup.Item action data-id={index} onClick={showSubmitFile} key={index}>
                                                            {file['FileName']}
                                                        </ListGroup.Item>)}
                                                    </ListGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    コメント
                                                </th>
                                                <td>
                                                    {Escape(Item['submits'][Submit]['answers']['comment'])}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    採点内容
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    講評
                                                </th>
                                                <td>{mode?Escape(Item['submits'][Submit]['grades']['comment']):
                                                    <FormControl as="textarea" placeholder="講評" defaultValue={Item['submits'][Submit]['grades']['comment']} ref={Comment} />}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    得点
                                                </th>
                                                <td>{mode?<>{Item['submits'][Submit]['grades']['score']}/{Item['reports']['score']}点</>:
                                                    <InputGroup>
                                                        <FormControl type="number" placeholder="得点" defaultValue={Item['submits'][Submit]['grades']['score']} ref={Score} />
                                                        <InputGroup.Text>/{Item['reports']['score']}点</InputGroup.Text>
                                                    </InputGroup>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="w-100 justify-content-between">
                                <Col sm="auto">
                                    {Item['submits'][Submit]['answers']['files'][SubmitFile]?<a href={Item['submits'][Submit]['answers']['files'][SubmitFile]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>:""}
                                </Col>{mode?"":
                                <Col sm="auto">
                                    <Button variatn="primary" type="button" onClick={doSubmit}>採点</Button>
                                </Col>}
                            </Row>
                        </Modal.Footer>
                    </Modal>:""}
                </div>
            </div>:""}
        </>
    );
}