import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import { useParams } from "react-router";
import Crumb from "../../controller/Crumb";
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap";
import SendJson from "../../controller/SendJson";

export default function AccountEdit(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const Wrapper=useState();
    useEffect(()=>{
        if(id=='new'){
            setItem({
                "AccountName": "",
                "stripes": {
                    "api": "",
                    "secret": "",
                    "manual":false
                },
                "paypays": {
                    "api": "",
                    "secret": "",
                    "merchant": "",
                    "manual":false
                },
                "paypals": {
                    "api": "",
                    "secret": "",
                    "merchant": "",
                    "manual":false
                }
            });
            setVisible(false)
        }else{
            Initialize();
        }
    },[])
    function Initialize(){
        let send={
            url:`v1/teacher/account/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function focus(e){
        e.target.select();
    }
    function doEntry(){
        setVisible(true);
        let send={
            url:`v1/teacher/account`+(id!='new'?`/${id}`:""),
            method:(id=='new'?'POST':'PUT'),
            body:{}
        }
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
        let AllError=error.concat();
        send['body']=body;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-stripe'),true);
        AllError=AllError.concat(error);
        send['body']['stripes']=body;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-paypay'),true);
        AllError=AllError.concat(error);
        send['body']['paypays']=body;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-paypal'),true);
        AllError=AllError.concat(error);
        send['body']['paypals']=body;
        if(error.length){
            setVisible(false)
            setAlert({type:'danger',msgs:AllError});
            return;
        }else{
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({type:'success',msgs:["決済連携を"+(id=='new'?"登録":"変更")+"しました。"]})
                    setItem();
                    if(id!='new'){
                        Initialize();
                    }else{
                        setVisible(false);
                    }
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                    setVisible(false);
                }
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
                setVisible(false);
            })
        }
    }
    return (
        <>
            {Item?<Crumb CurrentText={id=='new'?"新規決済連携":Item['AccountName']} items={[{index:0,href:'/',text:'ホーム'}]} />:""}
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div>
                <Table responsive striped ref={Wrapper}>
                    <tbody>
                        <tr>
                            <th>
                                登録名
                            </th>
                            <td>
                                <Form.Control type="text" name="AccountName" className="js-data" placeholder="登録名" defaultValue={Item['AccountName']} required />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Stripe
                            </th>
                            <td>{/*
                                <div className="my-2 input-group">
                                    <span className="input-group-text">手動決済</span>
                                    <select name="manual" className="form-select js-data-stripe" defaultValue={Item['stripes']['manual']} data-type="boolean">
                                        <option value={true}>許可する</option>
                                        <option value={false}>許可しない</option>
                                    </select>
                            </div>*/}
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                        公開APIキー
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="api" className="js-data-stripe" placeholder="公開APIキー" defaultValue={Item['stripes']['api']} />
                                </InputGroup>
                                <InputGroup >
                                    <InputGroup.Text>
                                        シークレットキー
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="secret" className="js-data-stripe" placeholder="シークレットキー" defaultValue={Item['stripes']['secret']}  />
                                </InputGroup>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                PayPay
                            </th>
                            <td>{/*
                                <div className="my-2 input-group">
                                    <span className="input-group-text">手動決済</span>
                                    <select name="manual" className="form-select js-data-paypay" defaultValue={Item['paypays']['manual']} data-type="boolean">
                                        <option value={true}>許可する</option>
                                        <option value={false}>許可しない</option>
                                    </select>
                            </div>*/}
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                        APIキー
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="api" className="js-data-paypay" placeholder="APIキー" defaultValue={Item['paypays']['api']}  />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                        シークレットキー
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="secret" className="js-data-paypay" placeholder="シークレットキー" defaultValue={Item['paypays']['secret']} />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                        マーチャントID
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="merchant" className="js-data-paypay" placeholder="マーチャントID" defaultValue={Item['paypays']['merchant']} />
                                </InputGroup>
                                <Card>
                                    <Card.Header>
                                        PayPay for Developersでの設定
                                    </Card.Header>
                                    <Card.Body>
                                        <ol>
                                        <li>
                                            <a href="https://developer.paypay.ne.jp/settings" target="_blank">PayPay for Developers \ 構成</a>に移動
                                        </li>
                                        <li>
                                            コールバックドメインを<Form.Control type="text" defaultValue="student.canteach.jp" onFocus={focus} readOnly />に設定
                                        </li>
                                        <li>
                                            突合ファイル通知Webhookを<Form.Control type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={focus} readOnly />に設定
                                        </li>
                                        <li>
                                            カスタマーイベント通知Webhookを<Form.Control type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={focus} readOnly />に設定
                                        </li>
                                        <li>
                                            決済トランザクション通知Webhookを<Form.Control type="text" defaultValue="https://api.canteach.jp/v1/webhook/paypay" onFocus={focus} readOnly />に設定
                                        </li>
                                        </ol>
                                    </Card.Body>
                                </Card>
                            </td>
                        </tr>
                        <tr>
                        <th>
                            PayPal
                        </th>
                            <td>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                        Client ID
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="client" className="js-data-paypal" placeholder="Client ID" defaultValue={Item['paypals']['client']} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>
                                        Secret
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="secret" className="js-data-paypal" placeholder="Secret" defaultValue={Item['paypals']['secret']} />
                                </InputGroup>{/*
                                <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        マーチャントID
                                    </span>
                                    <Form.Control type="text" name="merchant" className="js-data-paypal" placeholder="マーチャントID" defaultValue={Item['paypals']['merchant']} />
                                </div>*/}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={doEntry}>{id=='new'?"登録":"変更"}</Button>
            </div>:""}
        </>
    );
}