import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import SendJson from "../../controller/SendJson";
import UploadForm, { uploadFile } from "../../controller/UploadForm";
import {  Button, Col, Form, FormControl, Row, Table } from "react-bootstrap";

let all=[];
export default function InfoEntry(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Checked,setChecked]=useState([]);
    const [UploadFile,setUploadFile]=useState([]);

    useEffect(()=>{
        Initialize()
    },[])
    function Initialize(){
        setVisible(true)
        let send={
            url:`v1/course/${course}/student`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads'])
                data['payloads']['students'].map(student=>{
                    all.push(student['StudentId'])
                })
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function changeCheck(e){
        let idx=Checked.indexOf(e.target.value);
        let check=Checked.concat();
        if(idx>-1){
            check.splice(idx,1);
        }else{
            check=check.concat(e.target.value);
        }
        setChecked(check);
    }
    function allCheck(){
        setChecked(all);
    }
    function removeCheck(){
        setChecked([]);
    }
    function doEntry(){
        if(Checked.length){
            setVisible(true)
            uploadFile(UploadFile).then(data=>{
                if(data['result']){
                    let {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
                    if(error.length){
                        setAlert({'type':'danger','style':true,'msgs':error});
                        setVisible(false);
                        return;
                    }else{
                        body['to']=Checked;
                        body['files']=[];
                        for(let i=0;i<data['UploadFile'].length;++i){
                            body['files'].push(data['UploadFile'][i]['id']);
                        }
                        let send={
                            'url':'v1/course/'+course+'/contact',
                            'method':'POST',
                            'body':body
                        }
                        Fetch(send).then(data=>{
                            if(data['result']){
                                setItem({
                                    CourseName:Item['CourseName']
                                });
                                setAlert({type:'success',msgs:["お知らせを登録しました。"]});
                                setVisible(false);
                            }else{
                                setAlert({type:'danger',msgs:data['error']['description']})
                                setVisible(false);
                            }
                        }).catch(e=>{
                            console.log(e)
                            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
                            setVisible(false);
                        })
                    }
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                    setVisible(false);
                }
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
                setVisible(false);
            })
        }else{
            setAlert({type:'danger',msgs:["宛先を選択してください"]})
        }
    }
    return (
        <>
            {Item?<Crumb CurrentText="お知らせ登録" items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{(Item && Item['students'])?
            <div>
                <Table striped responsive>
                    <tbody>
                        <tr>
                            <th style={{width:"130px"}}>
                                宛先
                            </th>
                            <td>
                                <Button variant="secondary" type="button" onClick={allCheck} className="me-3">全選択</Button>
                                <Button variant="secondary" type="button" onClick={removeCheck}>全選択解除</Button>
                                <Row className="w-100 mt-3">{Item['students'].map(student=>
                                    <Col sm="2" key={student['StudentId']}>
                                        <Form.Check type="checkbox" id={student['StudentId']} value={student['StudentId']} label={student['UserName']} onChange={changeCheck} checked={Checked.indexOf(student['StudentId'])>-1?true:false} />
                                    </Col>)}
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <th style={{minWidth:"130px"}}>
                                タイトル
                            </th>
                            <td>
                                <FormControl type="text" className="js-data" name="ContactTitle" placeholder="タイトル" required />
                            </td>
                        </tr>
                        <tr>
                            <th style={{minWidth:"130px"}}>
                                内容
                            </th>
                            <td>
                                <FormControl as="textarea" className="js-data" name="ContactContent" placeholder="内容" required />
                            </td>
                        </tr>
                        <tr>
                            <th style={{minWidth:"130px"}}>
                                添付ファイル
                            </th>
                            <td>
                                <UploadForm setUploadFile={setUploadFile} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={doEntry}>
                    登録
                </Button>
            </div>:""}
        </>
    );
}