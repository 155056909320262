import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, FormSelect, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import { ConvertToDatetime } from "../../controller/keys";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import UploadForm, { uploadFile } from "../../controller/UploadForm";

export default function ReportEntry(){
    const {course,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [PublicDate,setPublicDate]=useState();
    const [UploadFile,setUploadFile]=useState();
    const [DeleteFile,setDeleteFile]=useState([]);

    const Wrapper=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/report/${id}`,
            method:'GET'
        }
        if(id!='new'){
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }else{
            send['url']=`v1/course/${course}`;
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem({
                        reports:{
                            "CourseName":data['payloads']['CourseName'],
                            "ReportId": "",
                            "ReportTitle": "",
                            "content": "",
                            "deadline": "",
                            "PublicDate": 0,
                            "EntriedNumber": 0,
                            "FromDate": "",
                            "ToDate": "",
                            "score": "",
                            "files": []
                        }
                    })
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }
    }
    function changePublicDate(e){
        setPublicDate(Number(e.target.value))
    }
    function removeFile(e){
        setDeleteFile(DeleteFile.concat(e.target.dataset.id));
    }
    function doEntry(){
        setVisible(true);
        uploadFile(UploadFile).then(data=>{
            if(data['result']){
                let {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
                if(error.length){
                    setAlert({type:"danger",msgs:error})
                    setVisible(false);
                    return;
                }else{
                    body['files']=[];
                    for(let i=0;i<data['UploadFile'].length;++i){
                        body['files'].push(data['UploadFile'][i]['id']);
                    }
                    body['deletes']=DeleteFile;
                    let send={
                        url:`v1/course/${course}/report`+(id=='new'?"":`/${id}`),
                        method:(id=='new'?"POST":"PUT"),
                        body:body
                    }
                    Fetch(send).then(data=>{
                        if(data['result']){
                            setItem();
                            setAlert({type:'success',msgs:["課題を"+(id=="new"?'登録':"変更")+"しました。"]})
                            Initialize();
                        }else{
                            setAlert({type:"danger",msgs:data['error']['description']})
                            setVisible(false)
                        }
                    }).catch(e=>{
                        console.log(e)
                        setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
                        setVisible(false)
                    })
                }
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    function doDelete(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/report/${id}`,
            method:"DELETE",
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["課題を削除しました。"]})
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={id=='new'?"新規課題":Item['reports']['ReportTitle']+"編集"} items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['reports']['CourseName']}].concat(id!='new' && {index:2,href:`/course/${course}/report/${id}`,text:Item['reports']['ReportTitle']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-3">
                    <Table striped ref={Wrapper}>
                        <tbody>
                            <tr>
                                <th>
                                    課題名
                                </th>
                                <td>
                                    <FormControl type="text" name="ReportTitle" className="js-data" defaultValue={Item['reports']['ReportTitle']} placeholder="課題名" required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                説明
                                </th>
                                <td>
                                    <FormControl as="textarea" name="content" className="js-data" placeholder="説明" defaultValue={Item['reports']['content']} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    提出期限
                                </th>
                                <td>
                                    <FormControl type="datetime-local" name="deadline" className="js-data" defaultValue={ConvertToDatetime(Item['reports']['deadline'])} placeholder="提出期限" required />
                                </td>
                            </tr>
                            <tr>
                                <th>公開期間</th>
                                <td>
                                    <Row className="w-100">
                                        <Col sm="auto" className="mb-2">
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    公開方式
                                                </InputGroup.Text>
                                                <FormSelect className="js-data" name="PublicDate" defaultValue={Item['reports']['PublicDate']} onChange={changePublicDate} required>
                                                    <option value={0}>絶対日時</option>
                                                    <option value={1}>相対日時</option>
                                                </FormSelect>
                                            </InputGroup>
                                        </Col>
                                        <Col sm="auto" style={{display:PublicDate?'block':'none'}}>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    受講登録から
                                                </InputGroup.Text>
                                                <FormControl type="number" name="EntriedNumber" className="js-data" defaultValue={Item['reports']['EntriedNumber']} />
                                                <InputGroup.Text>
                                                    日後
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col sm="auto" style={{display:PublicDate?'none':'block'}}>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    公開日
                                                </InputGroup.Text>
                                                <FormControl type="datetime-local" name="FromDate" className="js-data" defaultValue={ConvertToDatetime(Item['reports']['FromDate'])} />
                                                <InputGroup.Text>
                                                    から
                                                </InputGroup.Text>
                                                <FormControl type="datetime-local" name="ToDate" className="js-data" defaultValue={ConvertToDatetime(Item['reports']['ToDate'])} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    添付ファイル
                                </th>
                                <td>{Item['reports']['files'].length?
                                    <div className="mb-3">
                                        <p className="m-0">添付済み</p>
                                        <ListGroup>{Item['reports']['files'].map((file,index)=>
                                            <ListGroup.Item action data-id={file['FileId']} data-index={index} key={index}>
                                                <InputGroup className="w-100">
                                                    <FormControl value={file['FileName']} />
                                                    <a href={file['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>{DeleteFile.indexOf(file['FileId'])>-1?
                                                    <div className="btn btn-info">削除済み</div>:
                                                    <Button variant="danger" type="button" data-id={file['FileId']} data-index={index} onClick={removeFile}>削除</Button>}
                                                </InputGroup>
                                            </ListGroup.Item>)}
                                        </ListGroup>
                                    </div>:""}
                                    <div>
                                        <p className="m-0">ファイルアップロード</p>
                                        <UploadForm setUploadFile={setUploadFile} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                得点
                                </th>
                                <td>
                                    <InputGroup className="w-auto">
                                        <FormControl type="number" name="score" className="js-data" placeholder="得点" defaultValue={Item['reports']['score']} required />
                                        <InputGroup.Text>点</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="mt-2">
                        <Button variant="primary" type="button" onClick={doEntry}>
                            {id=='new'?"登録":"変更"}
                        </Button>{/*id=='new'?"":
                        <Button variant="danger" type="button" onClick={doDelete} className="ms-2">
                            削除
            </Button>*/}
                    </div>
                </div>
            </div>:""}
        </>
    );
}