import { useEffect, useState } from "react";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import { useParams } from "react-router";
import Crumb from "../../controller/Crumb";
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap";
import SendJson from "../../controller/SendJson";

export default function LineEdit(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const Wrapper=useState();
    useEffect(()=>{
        if(id=='new'){
            setItem({
                "LineName": "",
                "link": "",
                "login": {
                    "ChannelId": "",
                    "ChannelSecret": ""
                },
                "messaging": {
                    "ChannelId": "",
                    "ChannelSecret": "",
                    "AccessToken": ""
                }
            })
            setVisible(false)
        }else{
            Initialize();
        }
    },[])
    function Initialize(){
        let send={
            url:`v1/teacher/line/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function focus(e){
        e.target.select();
    }
    function doEntry(){
        setVisible(true);
        let send={
            url:`v1/teacher/line`+(id!='new' && `/${id}`),
            method:(id=='new'?'POST':'PUT'),
            body:{}
        }
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
        let AllError=error.concat();
        send['body']=body;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-login'),true);
        AllError=AllError.concat(error);
        send['body']['login']=body;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-messaging'),true);
        AllError=AllError.concat(error);
        send['body']['messaging']=body;
        if(error.length){
            setVisible(false)
            setAlert({type:'danger',msgs:AllError});
            return;
        }else{
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({type:'success',msgs:["公式LINE連携を"+(id=='new'?"登録":"変更")+"しました。"]})
                    setItem();
                    if(id!='new'){
                        Initialize();
                    }else{
                        setVisible(false);
                    }
                }else{
                    setAlert({type:'dagenr',msgs:data['error']['description']})
                    setVisible(false);
                }
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
                setVisible(false);
            })
        }
    }
    return (
        <>
            {Item?<Crumb CurrentText={id=='new'?"新規LINE連携":Item['LineName']} items={[{index:0,href:'/',text:'ホーム'}]} />:""}
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div>
                <Table responsive striped ref={Wrapper}>
                    <tbody>
                        <tr>
                            <th>
                                登録名
                            </th>
                            <td>
                                <Form.Control type="text" name="LineName" className="js-data" placeholder="登録名" defaultValue={Item['LineName']} required />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                公式LINE登録URL
                            </th>
                            <td>
                                <Form.Control type="url" name="link" className="js-data" placeholder="公式LINE登録URL" defaultValue={Item['link']} required />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                ログインAPI
                            </th>
                            <td>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>チャネルID</InputGroup.Text>
                                    <Form.Control type="text" name="ChannelId" className="js-data-login" placeholder="チャネルID" defaultValue={Item['login']['ChannelId']} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                    チャネルシークレット
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="ChannelSecret" className="js-data-login" placeholder="チャネルシークレット" defaultValue={Item['login']['ChannelSecret']} required />
                                </InputGroup>
                                <Card>
                                    <Card.Header>
                                    LINE Developersでの設定
                                    </Card.Header>
                                    <Card.Body>
                                        <ol>
                                            <li>
                                            <a href="https://developers.line.biz/ja/" target="_blank">LINE Developers</a>に移動
                                            </li>
                                            <li>
                                            チャネルを「公開」に設定
                                            </li>
                                            <li>
                                                <InputGroup>
                                                    <InputGroup.Text>コールバックURLを</InputGroup.Text>
                                                    <Form.Control type="text" defaultValue="https://student.canteach.jp/course/line/" onFocus={focus} readOnly />
                                                    <InputGroup.Text>に設定</InputGroup.Text>
                                                </InputGroup>
                                            </li>
                                        </ol>
                                    </Card.Body>
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                メッセージングAPI
                            </th>
                            <td>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>チャネルID</InputGroup.Text>
                                    <Form.Control type="text" name="ChannelId" className="js-data-messaging" placeholder="チャネルID" defaultValue={Item['messaging']['ChannelId']}  required />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                    チャネルシークレット
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="ChannelSecret" className="js-data-messaging" placeholder="チャネルシークレット" defaultValue={Item['messaging']['ChannelSecret']}  required />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                    アクセストークン
                                    </InputGroup.Text>
                                    <Form.Control type="text" name="AccessToken" className="js-data-messaging" placeholder="アクセストークン" defaultValue={Item['messaging']['AccessToken']}  required />
                                </InputGroup>
                                <Card>
                                    <Card.Header>
                                    LINE Developersでの設定
                                    </Card.Header>
                                    <Card.Body>
                                        <ol>
                                            <li>
                                            <a href="https://developers.line.biz/ja/" target="_blank">LINE Developers</a>に移動
                                            </li>
                                            <li>
                                                <InputGroup>
                                                    <InputGroup.Text>Webhookを</InputGroup.Text><Form.Control type="text" defaultValue="https://api.canteach.jp/v1/webhook/line" onFocus={focus} readOnly />
                                                    <InputGroup.Text>に設定</InputGroup.Text>
                                                </InputGroup>
                                            </li>
                                            <li>
                                            Webhookを「有効」に設定
                                            </li>
                                        </ol>
                                    </Card.Body>
                                </Card>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={doEntry}>{id=='new'?"登録":"変更"}</Button>
            </div>:""}
        </>
    );
}