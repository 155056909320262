import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, FormSelect, InputGroup, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function ReportEntry(){
    const {course,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Timing,setTiming]=useState('DATE');

    const Wrapper=useRef();
    const Param=useRef();
    const Content=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/step/${id}`,
            method:'GET'
        }
        if(id!='new'){
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                    setTiming(data['payloads']['timings']['timing']);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }else{
            send['url']=`v1/course/${course}`;
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem({
                        CourseName:data['payloads']['CourseName'],
                        StepTitle:"",
                        condition:true,
                        timings:{
                            timings:"DATE",
                            days:1,
                            time:10
                        },
                        content:""
                    })
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }
    }
    function addParam(){
        let content=Content.current.value;
        let BeforeLength=Content.current.selectionStart;
        Content.current.value=content.substr(0,BeforeLength)+Param.current.value+content.substr(BeforeLength);
    }
    function changeTiming(e){
        setTiming(e.target.value);
    }
    function doEntry(){
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
        let AllError=error.concat();
        let AllBody=Object.assign(body);
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-timings'),true);
        AllError=AllError.concat(error);
        AllBody['timings']=body;
        if(AllError.length){
            setAlert({type:"danger",msgs:AllError})
            setVisible(false);
            return;
        }else{
            let send={
                url:`v1/course/${course}/step`+(id=='new'?"":`/${id}`),
                method:(id=='new'?"POST":"PUT"),
                body:AllBody
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem();
                    setAlert({type:'success',msgs:["ステップを"+(id=="new"?'登録':"変更")+"しました。"]})
                    if(id!='new'){
                        Initialize();
                    }
                }else{
                    setAlert({type:"danger",msgs:data['error']['description']})
                    setVisible(false)
                }
            }).catch(e=>{
                console.log(e)
                setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
                setVisible(false)
            })
        }
    }
    function doDelete(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/step/${id}`,
            method:"DELETE",
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["ステップを削除しました。"]})
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
            }
            setVisible(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={id=='new'?"新規ステップ":Item['StepTitle']+"編集"} items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-3">
                    <Table striped responsive ref={Wrapper}>
                        <tbody>
                            <tr>
                                <th>
                                    ステップ名
                                </th>
                                <td>
                                    <FormControl type="text" name="StepTitle" className="js-data" placeholder="ステップ名" defaultValue={Item['StepTitle']} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    状態
                                </th>
                                <td>
                                    <FormSelect name="condition" className="js-data" defaultValue={Item['condition']} data-type="boolean" required>
                                        <option value={true}>配信中</option>
                                        <option value={false}>配信停止</option>
                                    </FormSelect>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    タイミング
                                </th>
                                <td>
                                    <Row className="w-100">
                                        <Col sm="auto">
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    配信タイミング
                                                </InputGroup.Text>
                                                <FormSelect name="timing" className="js-data-timings" defaultValue={Item['timings']['timing']} onChange={changeTiming} required>
                                                    <option value={"DATE"}>経過時間指定</option>
                                                    <option value={"SEND"}>一斉送信</option>
                                                </FormSelect>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup style={{display:Timing=="DATE"?"flex":"none"}}>
                                                <InputGroup.Text>
                                                    受講登録後
                                                </InputGroup.Text>
                                                <FormControl type="number" name="days" className="js-data-timings" min="1" defaultValue={Item['timings']['days']} placeholder="登録経過日時" />
                                                <InputGroup.Text>
                                                    日経過後の
                                                </InputGroup.Text>
                                                <FormControl type="number" name="time" className="js-data-timings" defaultValue={Item['timings']['time']} min="0" max="23" placeholder="時間" />
                                                <InputGroup.Text>
                                                    時に配信
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    配信内容
                                </th>
                                <td>
                                    <FormControl as="textarea" name="content" className="js-data" placeholder="配信内容" defaultValue={Item['content']} ref={Content} required />
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>パラメータ</InputGroup.Text>
                                        <FormSelect ref={Param}>
                                            <option value="[%name%]">お名前([%name%])</option>
                                        </FormSelect>
                                        <Button variant="secondary" onClick={addParam}>追加</Button>
                                    </InputGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="mt-2">
                        <Button variant="primary" type="button" onClick={doEntry}>
                            {id=='new'?"登録":"変更"}
                        </Button>{id=='new'?"":
                        <Button variant="danger" type="button" onClick={doDelete} className="ms-2">
                            削除
                        </Button>}
                    </div>
                </div>
            </div>:""}
        </>
    );
}