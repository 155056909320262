import { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, ListGroup } from "react-bootstrap";
import Upload from "./Upload";

export default function UploadForm({setUploadFile,accept="*"}){
    const [Files,setFiles]=useState([]);
    function setFile(e){
        let file=Files.concat();
        for(let i=0;i<e.target.files.length;++i){
            file.push({file:e.target.files[i],name:e.target.files[i]['name']});
        }
        setFiles(file);
        e.target.value="";
    }
    function setName(e){
        let newFile=Files.concat();
        newFile[e.target.dataset.id]['name']=e.target.value;
        setFiles(newFile);
    }
    function removeFile(e){
        let newFile=Files.concat();
        newFile.splice(e.target.dataset.id,1)
        setFiles(newFile);
    }
    useEffect(()=>{
        setUploadFile(Files);
    },[Files])
    return (
        <ListGroup>
            <ListGroup.Item action>
                <FormControl type="file" accept={accept} onChange={setFile} multiple />
            </ListGroup.Item>{Files.map((file,index)=>
            <ListGroup.Item key={index}>
                <InputGroup>
                    <FormControl type="text" data-id={index} placeholder="ファイル名" className="js-file-name" value={file['name']} onChange={setName} required />
                    <Button variant="danger" data-id={index} onClick={removeFile}>削除</Button>
                </InputGroup>
            </ListGroup.Item>)}
        </ListGroup>
    );
}
export function uploadFile(UploadFile){
    let fd=new FormData();
    let cnt=0;
    for(let i=0;i<UploadFile.length;++i){
        if(!UploadFile[i]['id']){
            fd.append(`file[${i}]`,UploadFile[i]['file']);
            fd.append(`FileName[${i}]`,UploadFile[i]['name']);
            fd.append(`FileId[${i}]`,i);
            ++cnt;
        }
    }
    return new Promise((resoleve,reject)=>{
        if(cnt){
            Upload(fd).then(data=>{
                if(data['result']){
                    Object.keys(data['payloads']['uploaded']).forEach(key=>{
                        UploadFile[key]['id']=data['payloads']['uploaded'][key];
                    })
                    return resoleve({
                        result:true,
                        UploadFile:UploadFile,
                        payloads:data['payloads']
                    });
                }else{
                    return resoleve({
                        result:false,
                        error:data['error']
                    })
                }
            }).catch((error)=>{
                return reject(error);
            });
        }else{
            return resoleve({
                result:true,
                UploadFile:UploadFile,
                payloads:{}
            });
        }
    })
}