import { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Form, FormCheck, FormControl, FormSelect, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import { ConvertToDatetime } from "../../controller/keys";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function Info(){
    const {course,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [PublicDate,setPublicDate]=useState();
    const [Quiz,setQuiz]=useState(false);
    const [Questions,setQuestions]=useState([]);
    const [Score,setScore]=useState([]);
    const Wrapper=useRef();

    useEffect(()=>{
        Initialize()
    },[])
    function Initialize(){
        setVisible(true);
        setItem();
        setScore([]);
        let send={
            url:`v1/course/${course}/examination/${id}`,
            method:'GET'
        }
        if(id=='new'){
            send['url']=`v1/course/${course}`;
            Fetch(send).then(data=>{
                if(data['result']){
                    let date=new Date();
                    let Fdate=new Date();
                    Fdate.setFullYear(Fdate.getFullYear()+5);
                    setItem({
                        "CourseName": data['payloads']['CourseName'],
                        "ExaminationTitle": "",
                        "note": "",
                        "deadline": Fdate,
                        "PublicDate": 1,
                        "EntriedNumber": 0,
                        "FromDate": date,
                        "ToDate": Fdate,
                        "multi": true,
                        "limit": 10,
                        "questions":[]
                    });
                    setPublicDate(0);
                    setQuestions([]);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }else{
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                    setPublicDate(data['payloads']['PublicDate']);
                    setQuestions(data['payloads']['questions']);
                    let score=[];
                    for(let i=0;i<data['payloads']['questions'].length;++i){
                        score.push(data['payloads']['questions'][i]['score']);
                    }
                    setScore(score);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }
    }
    function changePublicDate(e){
        setPublicDate(e.target.value)
    }
    function showQuiz(){
        setQuiz(!Quiz);
    }
    function addQuestion(){
        let newScore=Score.concat();
        newScore[Questions.length]=1;
        setScore(newScore);
        let newQuestion=Questions.concat({
            question:"",
            method:'ShortText',
            score:1,
            answer:"",
            QuestionId:Questions.length
        });
        setQuestions(newQuestion);
    }
    function removeQuestion(e){
        let newQuestion=Questions.concat();
        newQuestion.splice(e.target.dataset.index,1);
        setQuestions(newQuestion)
        let score=[];
        for(let i=0;i<newQuestion.length;++i){
            score.push(newQuestion[i]['score']);
        }
        setScore(score);
    }
    function changeQuestion(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['question']=e.target.value;
        setQuestions(newQuestion);
    }
    function changeMethod(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['method']=e.target.value;
        if(e.target.value.indexOf('Text')==-1){
            if(!newQuestion[e.target.dataset.index]['options']){
                newQuestion[e.target.dataset.index]['options']=[
                    "Option 1","Option 2"
                ];
            }
            if(e.target.value=="checkbox"){
                newQuestion[e.target.dataset.index]['answer']=[];
            }else if(Array.isArray(newQuestion[e.target.dataset.index]['answer'])){
                newQuestion[e.target.dataset.index]['answer']="";
            }
        }
        setQuestions(newQuestion);
    }
    function changeScore(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['score']=Number(e.target.value);
        setQuestions(newQuestion);
        let newScore=Score.concat();
        newScore[e.target.dataset.index]=Number(e.target.value);
        setScore(newScore);
    }
    function changeAnswer(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['answer']=e.target.value;
        setQuestions(newQuestion);
    }
    function changeOptionAnswer(e){
        let newQuestion=Questions.concat();
        if(newQuestion[e.target.dataset.index]['method']=='checkbox'){
            let idx=newQuestion[e.target.dataset.index]['answer'].indexOf(Number(e.target.value));
            if(idx>-1){
                newQuestion[e.target.dataset.index]['answer'].splice(idx,1)
            }else{
                newQuestion[e.target.dataset.index]['answer']=newQuestion[e.target.dataset.index]['answer'].concat(Number(e.target.value));
            }
        }else{
            newQuestion[e.target.dataset.index]['answer']=[Number(e.target.value)];
        }
        setQuestions(newQuestion);
    }
    function changeOptionText(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['options'][e.target.dataset.idx]=e.target.value;
        setQuestions(newQuestion);
    }
    function addOption(e){
        let newQuestion=Questions.concat();
        newQuestion[e.target.dataset.index]['options']=newQuestion[e.target.dataset.index]['options'].concat("");
        setQuestions(newQuestion);
    }
    function doEntry(){
        setVisible(true);
        let {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
        if(error.length){
            setAlert({type:'danger',msgs:error});
            setVisible(false);
            return;
        }else{
            body['questions']=Questions.concat();
            let send={
                'url':'v1/course/'+course+'/examination'+(id=='new'?"":`/${id}`),
                'method':(id=='new'?"POST":"PUT"),
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem();
                    setScore([]);
                    setAlert({'type':'success','msgs':[(id=='new'?"登録":"変更")+"しました。"]});
                    Initialize();
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                    setVisible(false);
                }
            }).catch(error=>{
                console.log(error);
                setVisible(false);
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            });
        }
    }
    return (
        <>
            {Item?<Crumb CurrentText={id=='new'?"新規テスト":Item['ExaminationTitle']+"編集"} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${course}`,text:Item['CourseName']}).concat(id!='new' && {index:2,href:`/course/${course}/examination/${id}`,text:Item['ExaminationTitle']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-4">
                    <Table striped responsive ref={Wrapper}>
                        <tbody>
                            <tr>
                                <th>
                                    テスト名
                                </th>
                                <td>
                                    <FormControl type="text" name="ExaminationTitle" className="js-data" placeholder="テスト名" defaultValue={Item['ExaminationTitle']} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                備考
                                </th>
                                <td>
                                    <FormControl as="textarea" name="content" className="js-data" placeholder="備考" defaultValue={Item['note']} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                解答期限
                                </th>
                                <td>
                                    <FormControl type="datetime-local" name="deadline" className="js-data" placeholder="解答期限" defaultValue={ConvertToDatetime(Item['deadline'])} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    公開期間
                                </th>
                                <td>
                                    <Row className="w-100">
                                        <Col sm="auto" className="mb-2">
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    公開方式
                                                </InputGroup.Text>
                                                <FormSelect className="js-data" name="PublicDate" defaultValue={Item['PublicDate']} onChange={changePublicDate} required>
                                                    <option value={0}>絶対日時</option>
                                                    <option value={1}>相対日時</option>
                                                </FormSelect>
                                            </InputGroup>
                                        </Col>
                                        <Col sm="auto" style={{display:PublicDate?'block':'none'}}>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    受講登録から
                                                </InputGroup.Text>
                                                <FormControl type="number" name="EntriedNumber" className="js-data" defaultValue={Item['EntriedNumber']} />
                                                <InputGroup.Text>
                                                    日後
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col sm="auto" style={{display:PublicDate?'none':'block'}}>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    公開日
                                                </InputGroup.Text>
                                                <FormControl type="datetime-local" name="FromDate" className="js-data" defaultValue={ConvertToDatetime(Item['FromDate'])} />
                                                <InputGroup.Text>
                                                    から
                                                </InputGroup.Text>
                                                <FormControl type="datetime-local" name="ToDate" className="js-data" defaultValue={ConvertToDatetime(Item['ToDate'])} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                複数回受験
                                </th>
                                <td>
                                    <FormSelect name="multi" className="js-data" defaultValue={Item['multi']} data-type="boolean" required>
                                        <option value={true}>許可</option>
                                        <option value={false}>許可しない</option>
                                    </FormSelect>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                制限時間
                                </th>
                                <td>
                                    <InputGroup>
                                        <FormControl type="number" name="limit" className="js-data" defaultValue={Item['limit']} min="1" required />
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                テスト問題
                                </th>
                                <td>
                                    <Button variant="secondary" type="button" onClick={showQuiz}>テスト問題作成</Button>
                                    <div className="mt-3">
                                        <span className="me-2">問題数：{Questions.length}</span>
                                        <span>合計得点：{Score.reduce((sum,element)=>sum+element,0)}点</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Modal show={Quiz} size="xl" onHide={showQuiz} fullscreen scrollable centered>
                        <Modal.Header closeButton>
                            <Modal.Title>テスト問題作成</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="justify-content-between mb-4">
                                <Col sm="auto">
                                    <Button variant="success" type="button" onClick={addQuestion}>問題追加</Button>
                                </Col>
                                <Col sm="auto" className="fs-3">
                                    合計得点：{Score.reduce((sum,element)=>sum+element,0)}点
                                </Col>
                            </Row>
                            <Accordion as="ol" defaultActiveKey={0} alwaysOpen>{Questions.map((question,index)=>
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>
                                        {index+1}.{question['question']}({question['score']}点)
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <InputGroup.Text>問題文</InputGroup.Text>
                                                <FormControl as="textarea" data-index={index} value={question['question']} placeholder="問題文" onChange={changeQuestion} />
                                            </InputGroup>
                                        </div>
                                        <div className="mb-2">
                                            <InputGroup>
                                                <InputGroup.Text>解答方法</InputGroup.Text>
                                                <FormSelect data-index={index} value={question['method']} onChange={changeMethod}>
                                                    <option value={"ShortText"}>短文</option>
                                                    <option value={"LongText"}>長文</option>
                                                    <option value={"checkbox"}>複数選択</option>
                                                    <option value={"radio"}>単一選択</option>
                                                </FormSelect>
                                                <InputGroup.Text>得点</InputGroup.Text>
                                                <FormControl type="number" value={question['score']} placeholder="得点" data-index={index} onChange={changeScore} />
                                                <InputGroup.Text>点</InputGroup.Text>
                                            </InputGroup>
                                        </div>
                                        <div className="mb-2">{question['method'].indexOf('Text')>-1?
                                            <InputGroup>
                                                <InputGroup.Text>解答</InputGroup.Text>
                                                {question['method']=='ShortText' && <FormControl type="text" data-index={index} value={question['answer']} placeholder="解答" onChange={changeAnswer} />}
                                                {question['method']=='LongText' && <FormControl as="textarea" data-index={index} value={question['answer']} placeholder="解答" onChange={changeAnswer} />}
                                            </InputGroup>:
                                            <ListGroup>
                                                <ListGroup.Item>
                                                    <Button type="button" variant="success" data-index={index} onClick={addOption}>選択肢の追加</Button>
                                                </ListGroup.Item>{question['options'].map((option,idx)=>
                                                <ListGroup.Item key={idx}>
                                                    <Row className="w-100">
                                                        <Col sm="auto">
                                                            <FormCheck type={question['method']} value={idx} data-index={index} data-idx={idx} checked={question['answer'].indexOf(idx)>-1?true:false} onChange={changeOptionAnswer} />
                                                        </Col>
                                                        <Col>
                                                            <FormControl type="text" placeholder="選択肢" value={option} data-index={index} data-idx={idx} onChange={changeOptionText} />
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>)}
                                            </ListGroup>}
                                        </div>
                                        <div className="text-end">
                                            <Button variant="danger" data-index={index} type="button" onClick={removeQuestion}>問題削除</Button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)}
                            </Accordion>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="primary" onClick={showQuiz}>完了</Button>
                        </Modal.Footer>
                    </Modal>
                    <Button variant="primary" type="button" onClick={doEntry}>{id=='new'?"登録":"変更"}</Button>
                </div>
            </div>:""}
        </>
    );
}
function ConvertMethod({item,answer=""}){
    switch(item['method']){
        case 'ShortText':
            return (<input type="text" name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} defaultValue={answer} readOnly={answer?true:false} placeholder="回答を入力してください" />);
            break;
        case 'LongText':
            return (<textarea name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} defaultValue={answer} readOnly={answer?true:false} placeholder="回答を入力してください" />);
            break;
        case 'radio':
        case 'checkbox':
            return(
                <div className="row js-data-opt" data-id={item['QuestionId']}>
                    {item['options'].map(opt=><Options type={item['method']} index={item['QuestionId']} option={opt.option} optIndex={opt.OptionId} key={opt['OptionId']} answer={answer} />)}
                </div>
            );
            break;
        case 'supplementation':
        default:
            return(<span></span>);
    }
}
function Options(props){
    return (
        <div className="col-auto me-2">
            <input type={props.type} name={props.index} id={"question_"+props.index+'_'+props.optIndex} className="form-check-input js-opt" data-id={props.index} value={props.optIndex} key={props.optIndex} defaultChecked={props.answer.indexOf(props.optIndex)>-1?true:false} readOnly={props.answer?true:false} />
            <label htmlFor={"question_"+props.index+'_'+props.optIndex} className="form-check-label">
                {props.option}
            </label>
        </div>
    );
}