import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Button, FormControl, Modal, Table } from "react-bootstrap";
import { getUser } from "../..";
import Syllabus from "../common/Syllabus";
import SendJson from "../../controller/SendJson";

export default function Teacher(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [sSyllabus,setSyllabus]=useState(false);
    const navigate=useNavigate();

    const User=getUser();
    const Wrapper=useRef();

    useEffect(()=>{
        let send={
            url:'v1/course/'+course,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                data['payloads']['syllabus']['teachers'].map(teacher=>{
                    if(teacher['UserId']==User['UserId']){
                        navigate(`/course/${course}`);
                    }
                })
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    },[])
    function showSyllabus(){
        setSyllabus(!sSyllabus);
    }
    function closeModal(){
        setSyllabus(false);
    }
    function doEntry(){
        setVisible(true);
        let {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-teacher'),true);
        if(error.length){
            setAlert({type:'danger',msgs:error})
            setVisible(false);return;
        }else{
            let send={
                url:`v1/course/${course}/teacher`,
                method:'POST',
                body:body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    navigate(`/course/${course}`)
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                    setVisible(false);
                }
            }).catch(e=>{
                console.log(e)
                setVisible(false);
                setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            })
        }
    }
    return (
        <>
            {Item?<Crumb CurrentText={"講師登録"} items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <p>{Item['CourseName']}の講師登録を行います。</p>
                <div>
                    <Button variant="secondary" onClick={showSyllabus}>シラバスの表示</Button>
                    <Table className="my-2" responsive ref={Wrapper}>
                        <thead>
                            <tr>
                                <td colSpan={2}>
                                    {User['UserName']}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    Webページ
                                </th>
                                <td>
                                    <FormControl type="url" name="link" className="js-data-teacher" placeholder="Webページ" />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    メールアドレス
                                </th>
                                <td>
                                    <FormControl type="email" name="email" className="js-data-teacher" placeholder="メールアドレス" />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    電話番号
                                </th>
                                <td>
                                    <FormControl type="tel" name="tel" className="js-data-teacher" placeholder="電話番号" />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    公式LINE登録URL
                                </th>
                                <td>
                                    <FormControl type="url" name="line" className="js-data-teacher" placeholder="公式LINE登録URL" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="primary" onClick={doEntry}>登録</Button>
                </div>
                <Modal size="xl" show={sSyllabus} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>シラバス</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Syllabus Item={Item} />
                    </Modal.Body>
                </Modal>
            </div>:""}
        </>
    );
}