import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, FormControl, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Escape from "../../controller/Escape";
import Fetch from "../../controller/Fetch";
import { ConvertToDisplayDate } from "../../controller/keys";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import Mode from "../common/mode";

let timer;
let limit;
export default function Info(){
    const {course,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Exam,setExam]=useState(false);
    const [Timer,setTimer]=useState(0);
    const [Info,setInfo]=useState();
    const [mode,setMode]=useState();
    const [Submit,setSubmit]=useState();
    const ExamArea=useRef();
    const Wrapper=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/examination/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                limit=data['payloads']['limit']*60;
                setTimer(limit)
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
            setExam(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    }
    function start(){
        setExam(true)
        timer=setInterval(()=>{
            limit-=1;
            setTimer(limit);
            if(limit<0){
                window.alert("制限時間が来たため，提出します。");
                setAlert({'type':'success','msgs':["提出しました。"]});
                setExam(false);
                clearInterval(timer)
            }else if(limit<=60){
                setInfo('残り時間が1分を切りました。')
            }
        },1000)
    }
    function convertTime(time){
        let min=Math.floor(time % 3600 /60);
        let rem=time % 60;
        return "残り"+(min?`${min}分`:"")+rem+"秒";
    }
    function doSubmit(){
        if(window.confirm('提出してもよろしいですか？')){
            setAlert({'type':'success','msgs':["提出しました。"]});
            setExam(false);
            clearInterval(timer)
        }
    }
    function showSubmit(e){
        setSubmit(e.target.dataset.index);
        setAlert();
    }
    function closeModal(){
        setSubmit();
    }
    function doEntry(){
        setVisible(true);
        let AllBody=[];
        let AllError=[];
        let data=Wrapper.current.getElementsByClassName('js-data');
        for(let i=0;i<data.length;++i){
            var {error,body}=SendJson(data[i].getElementsByClassName('js-data-quiz'),true);
            if(error.length){
                AllError=AllError.concat(error);
            }else{
                body['QuestionId']=data[i].dataset.id;
                AllBody.push(body)
            }
        }
        if(AllError.length){
            setAlert({type:'danger',msgs:AllError});
            setVisible(false);
            return;
        }else{
            let send={
                'url':'v1/course/'+course+'/examination/'+id+`/grading/${Item['submits'][Submit]['StudentExaminationId']}`,
                'method':'PUT',
                'body':AllBody
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','msgs':["採点しました。"]});
                    setItem();
                    Initialize();
                    closeModal();
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                    setVisible(false);
                }
            }).catch(error=>{
                console.log(error);
                setVisible(false);
                setExam(false);
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            });
        }
    }
    return (
        <>
            <Mode setMode={setMode} />
            {Item?<Crumb CurrentText={Item['ExaminationTitle']} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${course}`,text:Item['CourseName']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-4">
                    <h2>
                        {Item['ExaminationTitle']}
                    </h2>
                    <Table striped responsive>
                        <tbody>
                            <tr>
                                <th>
                                備考
                                </th>
                                <td>
                                    {Escape(Item['note'])}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                解答期限
                                </th>
                                <td>
                                    {ConvertToDisplayDate(Item['deadline'],true)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    公開期間
                                </th>
                                <td>{Item['PublicDate']?
                                    `受講登録${Item['EntriedNumber']}日後`:
                                    `${ConvertToDisplayDate(Item['FromDate'],true)}から${ConvertToDisplayDate(Item['ToDate'],true)}まで`}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                複数回受験
                                </th>
                                <td>
                                {Item['multi'] ? <span>許可</span>:<span>許可されていません</span>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                制限時間
                                </th>
                                <td>
                                {Item['limit']}分
                                </td>
                            </tr>
                            <tr>
                                <th>
                                得点
                                </th>
                                <td>
                                {Item['score']}点
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="text-end">
                        <Link to={`/course/${course}/examination/${id}/edit`} className="btn btn-success">編集</Link>
                    </div>
                </div>{mode?
                <div className="my-4">
                    <Button variant="primary" onClick={start}>テスト開始</Button>
                    <Modal fullscreen={true} show={Exam} onHide={doSubmit} scrollable>
                        <Modal.Header>
                            <Modal.Title className="row justify-content-between w-100">
                                <Col sm="auto">
                                    {Item['ExaminationTitle']}
                                </Col>{Info?
                                <Col className="bg-info text-center">
                                    {Info}
                                </Col>:""}
                                <Col sm="auto" className={Timer<30?"bg-danger":(Timer<60?"bg-warning":"")}>
                                    {convertTime(Timer)}
                                </Col>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ol ref={ExamArea}>{Item['questions'].map(quiz=>
                                <li key={quiz['QuestionId']} className="mb-2">
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>
                                                {quiz['question']}
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ConvertMethod item={quiz} />
                                        </Card.Body>
                                    </Card>
                                </li>)}</ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={doSubmit}>
                                提出
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>:""}
                <div className="mt-4">
                    <h3>
                        受験済みテスト
                    </h3>
                    <ListGroup>{Item['submits'].map((submit,index)=>
                        <ListGroup.Item action={!mode} key={submit['StudentExaminationId']} data-index={index} onClick={showSubmit}>{mode?
                            <>{ConvertToDisplayDate(submit['SubmitDatetime'],true)} 提出</>:
                            <>{submit['UserName']}({ConvertToDisplayDate(submit['SubmitDatetime'],true)} 提出)</>}
                        </ListGroup.Item>)}
                    </ListGroup>{!mode && !isNaN(Submit)?
                    <Modal size="xl" show={true} onHide={closeModal} scrollable centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                テストの採点
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
                            <div>
                                <h4>
                                    提出日時
                                </h4>
                                <p>{ConvertToDisplayDate(Item['submits'][Submit]['SubmitDatetime'],true)}</p>
                            </div>
                            <div>
                                <h4>
                                    解答内容
                                </h4>
                                <ol ref={Wrapper}>{Item['submits'][Submit]['answers'].map((answer,index)=>
                                    <li key={index} className="js-data" data-id={answer['QuestionId']}>
                                        <div>
                                            {Item['questions'][answer['QuestionId']]['question']}
                                        </div>
                                        <ConvertMethod item={Item['questions'][answer['QuestionId']]} answer={answer['answer']} />
                                        <div className="my-2">
                                            <InputGroup className="mb-2">
                                                <InputGroup.Text>
                                                    講評
                                                </InputGroup.Text>
                                                <FormControl as="textarea" name="comment" className="js-data-quiz" defaultValue={answer['gradings']['comment']} placeholder="講評" />
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                                <InputGroup.Text>
                                                    得点
                                                </InputGroup.Text>
                                                <FormControl type="number" name="score" className="js-data-quiz" defaultValue={answer['gradings']['score']} placeholder="得点" min="0" max={Item['questions'][answer['QuestionId']]['score']} required />
                                                <InputGroup.Text>
                                                /{Item['questions'][answer['QuestionId']]['score']}点
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </div>
                                    </li>)}
                                </ol>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={doEntry} type="button">採点</Button>
                        </Modal.Footer>
                    </Modal>:""}
                </div>
            </div>:""}
        </>
    );
}
function ConvertMethod({item,answer=""}){
    switch(item['method']){
        case 'ShortText':
            return (<input type="text" name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} defaultValue={answer} readOnly={answer?true:false} placeholder="回答を入力してください" />);
            break;
        case 'LongText':
            return (<textarea name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} defaultValue={answer} readOnly={answer?true:false} placeholder="回答を入力してください" />);
            break;
        case 'radio':
        case 'checkbox':
            return(
                <div className="row js-data-opt w-100" data-id={item['QuestionId']}>
                    {item['options'].map((opt,index)=><Options type={item['method']} index={item['QuestionId']} option={opt} optIndex={index} key={index} answer={answer} />)}
                </div>
            );
            break;
        case 'supplementation':
        default:
            return(<span></span>);
    }
}
function Options(props){
    console.log(props)
    return (
        <div className="col-auto me-2">
            <input type={props.type} name={props.index} id={"question_"+props.index+'_'+props.optIndex} className="form-check-input js-opt" data-id={props.index} value={props.optIndex} key={props.optIndex} defaultChecked={props.answer.indexOf(props.optIndex)>-1?true:false} disabled={props.answer?true:false} />
            <label htmlFor={"question_"+props.index+'_'+props.optIndex} className="form-check-label">
                {props.option}
            </label>
        </div>
    );
}