import { Table } from "react-bootstrap";
import Escape from "../../controller/Escape";
import { ConvertToDisplayDate, CourseFree, Domain } from "../../controller/keys";

export default function Syllabus({Item}){
    return(
        <Table striped responsive>
            <tbody>
                <tr>
                    <th>
                    コース名
                    </th>
                    <td>
                    {Item['syllabus']['CourseName']}
                    </td>
                </tr>
                <tr>
                    <th>
                    開講期間
                    </th>
                    <td>
                    {ConvertToDisplayDate(Item['syllabus']['term'][0])}から{ConvertToDisplayDate(Item['syllabus']['term'][1])}まで
                    </td>
                </tr>
                <tr>
                    <th>
                    講師
                    </th>
                    <td>
                    <div className="list-group list-group-flush">
                        {Item['syllabus']['teachers'].map((teacher,index)=><a href={Domain('home',"profile/teacher/"+teacher['TeacherId'])} className="list-group-item list-group-item-action" target="_blank" key={index}>{teacher.UserName}</a>)}
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>
                    講義方法
                    </th>
                    <td>
                        {Escape(Item['syllabus']['CourseMethod'])}
                    </td>
                </tr>
                <tr>
                    <th>
                    受講レベル
                    </th>
                    <td>
                        {Item['syllabus']['CourseLevel']}
                    </td>
                </tr>
                <tr>
                    <th>
                    キーワード
                    </th>
                    <td>
                        <ul>
                        {Item['syllabus']['keywords'].map(key=><li key={key}>{key}&nbsp;</li>)}
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>
                    最大受講者数
                    </th>
                    <td>
                        {Item['syllabus']['MaxStudent']}名
                    </td>
                </tr>
                <tr>
                    <th>
                    初期費用
                    </th>
                    <td>
                        {Item['syllabus']['SetupPrice']}円
                    </td>
                </tr>
                <tr>
                    <th>
                    月額費用
                    </th>
                    <td>
                        {Item['syllabus']['MonthlyPrice']}円/月{Item['syllabus']['AccountNumber']?' × '+Item['syllabus']['AccountNumber']+'月':''}
                    </td>
                </tr>
                <tr>
                    <th>
                    無料期間
                    </th>
                    <td>
                        {CourseFree(Item['syllabus']['free'])}
                    </td>
                </tr>
                <tr>
                    <th>
                    講義の目標
                    </th>
                    <td>
                        {Escape(Item['syllabus']['goal'])}
                    </td>
                </tr>
                <tr>
                    <th>
                    講義計画
                    </th>
                    <td>
                        <ol>
                            {Item['syllabus']['plans'].map((plan,index)=><li key={index}>{plan}</li>)}
                        </ol>
                    </td>
                </tr>
                <tr>
                    <th>
                    必要物
                    </th>
                    <td>
                        <ul>
                            {Item['syllabus']['needs'].map((need,index)=><li key={index}>{need}</li>)}
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>
                    受講の注意事項
                    </th>
                    <td>
                        {Escape(Item['syllabus']['attention'])}
                    </td>
                </tr>
                <tr>
                    <th>
                        契約書
                    </th>
                    <td>
                        <a href={Item['syllabus']['contract']['link']} target="_blank">{Item['syllabus']['contract']['name']}</a>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}