import { createStore } from "redux";

let init={
    mode:false
}

const reducer=(state=init,action)=>{
    switch(action.type){
        case 'ChangeMode':
            return Object.assign(state,{mode:action.payload});
        default:
            return state;
    }
}

export function ChangeMode(payload){
    return {
        type:"ChangeMode",
        payload:payload
    }
}
export default createStore(reducer);
