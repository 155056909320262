import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import Caroucel from "../common/Caroucel";
import CardList from "../../controller/CardList";
import { ConvertToDisplayDate, CourseFree, createKey, Domain, StudentCondition, TextProgress } from "../../controller/keys";
import { Accordion, Button, Card, Col, FormControl, InputGroup, ListGroup, Modal, ProgressBar, Row, Table } from "react-bootstrap";
import Escape from "../../controller/Escape";
import LineBtn from "../common/LineBtn";
import { Link } from "react-router-dom";
import Mode from "../common/mode";
import Syllabus from "../common/Syllabus";
import { getUser } from "../..";

export default function Detail(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [mode,setMode]=useState(false);
    const [sSyllabus,setSyllabus]=useState(false);
    const [Teacher,setTeacher]=useState(false);
    const [Entry,setEntry]=useState(false);

    //受講者モード
    const [ReportGrade,setReportGrade]=useState(false);
    const [ExamGrade,setExamGrade]=useState(false);
    const [Contract,setContract]=useState(false);
    const [Cancellation,setCancellation]=useState(false);

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        let send={
            url:'v1/course/'+course,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let tmp=data['payloads']['texts'];
                let text=[];
                for(let i=0;i<tmp.length;++i){
                    text.push({icon:tmp[i]['icon']['link'],text:tmp[i]['FolderName'],link:`/course/${course}/folder/${tmp[i]['FolderId']}`})
                    //text.push({icon:"https://canteach.jp/assets/img/icon192x192.png",text:tmp[i]['FolderName']+"0",link:`/course/${course}/text/${tmp[i]['FolderId']}`})
                }
                tmp=data['payloads']['contacts']
                let contact=[];
                for(let i=0;i<tmp.length;++i){
                    contact.push({index:i,href:`/course/${course}/contact/${tmp[i]['ContactId']}`,text:tmp[i]['ContactTitle']})
                }
                tmp=data['payloads']['reports']
                let report=[];
                for(let i=0;i<tmp.length;++i){
                    report.push({index:i,href:`/course/${course}/report/${tmp[i]['ReportId']}`,text:tmp[i]['ReportTitle']})
                }
                tmp=data['payloads']['examinations']
                let examination=[];
                for(let i=0;i<tmp.length;++i){
                    examination.push({index:i,href:`/course/${course}/examination/${tmp[i]['ExaminationId']}`,text:tmp[i]['ExaminationTitle']})
                }
                tmp=data['payloads']['steps']
                let steps=[];
                for(let i=0;i<tmp.length;++i){
                    steps.push({index:i,href:`/course/${course}/step/${tmp[i]['StepId']}`,text:tmp[i]['StepTitle']})
                }
                setItem(Object.assign(data['payloads'],{
                    CourseName:data['payloads']['CourseName'],
                    text:text,
                    contact:contact,
                    report:report,
                    examination:examination,
                    step:steps
                }));
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function showSyllabus(){
        setSyllabus(!sSyllabus);
    }
    function showTeacher(){
        setTeacher(!Teacher);
    }
    function showEntry(){
        setEntry(!Entry)
    }
    function closeModal(){
        setSyllabus(false);
        setTeacher(false)
        setEntry(false)

        //受講者モード
        setReportGrade(false);
        setExamGrade(false);
        setContract(false);
        setCancellation(false);
    }
    function focusUrl(e){
        e.target.select();
    }
    function doDeleteTeacher(e){
        setVisible(true)
        let send={
            url:`v1/course/${course}/teacher/${e.target.dataset.id}`,
            method:'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["講師を削除しました。"]})
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']});
                setVisible(false);
            }
        }).catch(e=>{
            console.log(e)
            setVisible(false)
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }

    //受講者モード
    function showReportGrade(){
        setReportGrade(!ReportGrade)
    }
    function showExamGrade(){
        setExamGrade(!ExamGrade)
    }
    function showContract(){
        setContract(!Contract);
    }
    function showCancellation(){
        setCancellation(!Cancellation);
    }
    function applyCancel(){
        setAlert({type:'success',msgs:["解約申請をしました。"]})
        closeModal()
    }
    return (
        <>
            <Mode setMode={setMode} />
            {Item?<Crumb CurrentText={Item['CourseName']} items={[{index:0,href:'/',text:'ホーム'}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                {/* 教材フォルダ */}
                <Caroucel items={Item['text']} />
                <div>{!mode?
                <Link to={`/course/${course}/folder`} className="btn btn-secondary mt-1">フォルダー管理</Link>:""}
                </div>
                {/*教材進捗状況*/}{mode?"":
                <div className="my-3">
                    <Card>
                        <Card.Header>
                            進捗状況
                        </Card.Header>
                        <Card.Body>{Item['progress']['users'].map((progress)=>
                            <Accordion key={progress['StudentId']} flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <Row className="w-100">
                                            <Col sm="2">
                                                {progress['UserName']}
                                            </Col>
                                            <Col>
                                                <ProgressBar now={progress['score']} max={Item['progress']['folders']['summary']['allTextLength']} label={`${progress['score']}/${Item['progress']['folders']['summary']['allTextLength']}`} className="w-100" />
                                            </Col>
                                        </Row>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Accordion flush>{Item['progress']['folders']['details'].map((folder,FolderId)=>
                                            <Accordion.Item eventKey={FolderId} key={FolderId}>
                                                <Accordion.Header>
                                                    <Row className="w-100">
                                                        <Col sm="2">{folder['FolderName']}</Col>
                                                        <Col>
                                                            <ProgressBar now={progress['folders'][FolderId]['summary']['score']} max={folder['TextLength']?folder['TextLength']:1} label={`${progress['folders'][FolderId]['summary']['score']}/${folder['TextLength']}`} className="w-100" />{/* label={`${progress['folders'][FolderId]['summary']['score']}/${folder['TextLength']}`}*/}
                                                        </Col>
                                                    </Row>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <td>
                                                                    教材名
                                                                </td>
                                                                <td>
                                                                    状態
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{Object.keys(folder['texts']).map(text=>
                                                            <tr key={text}>
                                                                <td>
                                                                    {folder['texts'][text]}
                                                                </td>
                                                                <td>
                                                                    {TextProgress(progress['folders'][FolderId]['details'][text])}
                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>)}
                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>)}
                        </Card.Body>
                    </Card>
                </div>}
                {/*リスト*/}
                <div className="row">
                    <div className="col-sm-6 mt-4">
                        <CardList title="お知らせ" items={Item['contact']} />{!mode?
                        <Link to={`/course/${course}/contact`} className="btn btn-secondary mt-1">お知らせ登録</Link>:""}
                    </div>
                    <div className="col-sm-6 mt-4">
                        <CardList title="課題" items={Item['report']} />{!mode?
                        <Link to={`/course/${course}/report/new/edit`} className="btn btn-secondary mt-1">課題登録</Link>:""}
                    </div>
                    <div className="col-sm-6 mt-4">
                        <CardList title="テスト" items={Item['examination']} />{!mode?
                        <Link to={`/course/${course}/examination/new/edit`} className="btn btn-secondary mt-1">テスト登録</Link>:""}
                    </div>
                    <div className="col-sm-6 mt-4">
                        <CardList title="LINEステップ" items={Item['step']} />{!mode?
                        <Link to={`/course/${course}/step/new`} className="btn btn-secondary mt-1">LINEステップ登録</Link>:""}
                    </div>{!mode?
                    <div className="col-sm-6 mt-4">
                        <Card className="w-100">
                            <Card.Header>
                                その他
                            </Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item action onClick={showSyllabus}>
                                        シラバス
                                    </ListGroup.Item>
                                    <Link to={`/course/${course}/edit`} className="list-group-item list-group-item-action">
                                        シラバス編集
                                    </Link>
                                    <ListGroup.Item action onClick={showTeacher}>
                                        講師管理
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={showEntry}>
                                        受講登録URL
                                    </ListGroup.Item>
                                    <Link to={`/course/${course}/student`} className="list-group-item list-group-item-action">
                                        受講者情報
                                    </Link>
                                    <Link to={`/course/${course}/grade`} className="list-group-item list-group-item-action">
                                        成績
                                    </Link>
                                    <Link to={`/course/${course}/cancellation`} className="list-group-item list-group-item-action">
                                        解約申請
                                    </Link>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </div>:
                    <>
                        <div className="col-sm-6 mt-4">
                            <Card className="w-100">
                                <Card.Header>
                                    成績
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item action onClick={showReportGrade}>
                                            <div className="row">
                                                <div className="col">
                                                    課題
                                                </div>
                                                <div className="col-auto">
                                                    90/100点
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item action onClick={showExamGrade}>
                                            <div className="row">
                                                <div className="col">
                                                    テスト
                                                </div>
                                                <div className="col-auto">
                                                    90/100点
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <Card className="w-100">
                                <Card.Header>
                                    その他
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        <a href={Domain("home",`account/payment`)} className="list-group-item list-group-item-action" target="_blank">
                                            決済履歴
                                        </a>
                                        <ListGroup.Item action onClick={showSyllabus}>
                                            シラバス
                                        </ListGroup.Item>
                                        <ListGroup.Item action onClick={showContract}>
                                            契約内容
                                        </ListGroup.Item>
                                        <ListGroup.Item action onClick={showCancellation}>
                                            解約申請
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                    </>}
                </div>
                {/*モーダル*/}
                <Modal size="xl" show={sSyllabus} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>シラバス</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Syllabus Item={Item} />
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={Teacher} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>講師管理</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup variant="flush">{Item['teachers'].map(teacher=>
                            <ListGroup.Item key={teacher['TeacherId']} action>
                                <InputGroup>
                                    <a href={Domain('home',`/profile/teacher/${teacher['TeacherId']}`)} target="_blank" className="form-control text-decoration-none">{teacher['UserName']}</a>
                                    {Item['UserId']==teacher['UserId']?"":<Button variant="danger" type="button" data-id={teacher['TeacherId']} onClick={doDeleteTeacher}>削除</Button>}
                                </InputGroup>
                            </ListGroup.Item>)}
                        </ListGroup>
                        <h5>
                            講師の追加
                        </h5>
                        <div>
                            <p>以下のURLを講師に追加したいユーザーに送信し，アクセスするように伝えてください。</p>
                            <FormControl type="url" value={`https://teacher.canteach.jp/course/${course}/teacher`} readOnly onFocus={focusUrl} />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={Entry} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>コース登録</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl type="url" value={Domain('course',course)} onFocus={focusUrl} readOnly />
                    </Modal.Body>
                </Modal>
                {/*受講者モード*/}
                <Modal show={(ReportGrade || ExamGrade)?true:false} onHide={closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{ReportGrade?"課題成績":"テスト成績"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped>
                            <thead>
                                <tr>
                                    <td>
                                        {ReportGrade?"課題名":"テスト名"}
                                    </td>
                                    <td>
                                        得点
                                    </td>
                                </tr>
                            </thead>
                            <tbody>{ReportGrade?Item['reports'].map(detail=>
                                <tr key={detail['ReportId']}>
                                    <td>
                                        {detail['ReportTitle']}(提出済み)
                                    </td>
                                    <td>
                                        (サンプル得点)50/100点
                                    </td>
                                </tr>
                            ):Item['examinations'].map(detail=>
                                <tr key={detail['ExaminationId']}>
                                    <td>
                                        {detail['ExaminationTitle']}(未提出)
                                    </td>
                                    <td>
                                        (サンプル得点)50/100点
                                    </td>
                                </tr>
                            )}</tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={Contract} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>契約内容</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-6">
                                <iframe src={Item['syllabus']['contract']['link']} className="iframe-file" />
                            </div>
                            <div className="col-sm-6">
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                同意日時
                                            </th>
                                            <td>
                                                {ConvertToDisplayDate(new Date())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                同意者名
                                            </th>
                                            <td>
                                                {getUser()['UserName']}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={Cancellation} onHide={closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>解約申請</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            本当に解約申請をしますか？<br />
                            ※既に支払われた受講料は返金されません。
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="danger" onClick={applyCancel}>
                                解約する
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>:""}
        </>
    );
}