import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Button, Card, Col, FormControl, FormSelect, InputGroup, ListGroup, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import Escape from "../../controller/Escape";
import { Link } from "react-router-dom";
import Mode from "../common/mode";
import { ConvertToDatetime, TextProgress } from "../../controller/keys";
import UploadForm, { uploadFile } from "../../controller/UploadForm";
import SendJson from "../../controller/SendJson";

export default function Text({edit=false}){
    const {course,folder,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [mode,setMode]=useState(false);
    const [Text,setText]=useState();
    const [BCrumb,setBCrumb]=useState();
    const [File,setFile]=useState();
    const [Edit,setEdit]=useState(edit);
    const [TextItem,setTextItem]=useState();
    const [UploadFile,setUploadFile]=useState([]);
    const [AttachedFile,setAttachedFile]=useState({files:[],deletes:[]});
    const [PublicDate,setPublicDate]=useState();
    const Wrapper=useRef();
    const navigate=useNavigate();

    //受講者モード

    useEffect(()=>{
        if(edit){
            window.location.pathname=`/course/${course}/folder/${folder}/text/${id}`;
        }
        Initialize();
    },[])
    useEffect(()=>{
        setText(id)
        if(!id){
            setBCrumb();
        }
    },[id])
    useEffect(()=>{
        setEdit(edit);
        TextInitialize(TextItem,Item,Text);
    },[edit])
    function TextInitialize(textItem,item,text){
        if(item && item['texts'] && textItem){
            if(id=='new'){
                setItem(Object.assign(item,{
                    texts:item['texts'].concat({
                        TextId:"new",
                        "TextName": "",
                        "ClassMethod": "",
                        "video": {
                            "LinkName": "",
                            "link": "",
                            "explanation": ""
                        },
                        "note": "",
                        "PublicDate": 0,
                        "EntriedNumber": 0,
                        "FromDate": "",
                        "ToDate": "",
                        "free": "",
                        files:[]
                    })
                }))
                setTextItem(Object.assign(textItem,{
                    'new':Object.keys(textItem).length
                }))
                setAttachedFile({files:[],deletes:[]});
                setPublicDate(0);
            }else{
                setAttachedFile({files:item['texts'][textItem[text]]['files'],deletes:[]});
                setPublicDate(item['texts'][textItem[text]]['PublicDate']);
            }
        }else{
            setEdit(false)
        }
    }
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/folder/${folder}/text`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                let textItem={};
                data['payloads']['texts'].map((text,idx)=>{
                    textItem[text['TextId']]=idx;
                })
                if(edit){
                    TextInitialize(textItem,data['payloads'],id);
                }else{
                    setTextItem(textItem);
                }
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    
    function changeText(e){
        setText(e.target.dataset.id);
        setBCrumb(Item['texts'][e.target.dataset.index]['TextName']);
        setFile();
        setEdit(false);
    }
    function showFile(e){
        setFile(e.target.dataset.id)
    }
    function changePublicDate(e){
        setPublicDate(Number(e.target.value))
    }
    function closeModal(){
        setFile();
        setEdit(false);
        //受講者モード

        navigate(`/course/${course}/folder/${folder}/text/${id}`);
    }
    function removeAttachedFile(e){
        setAttachedFile({files:AttachedFile['files'],deletes:AttachedFile['deletes'].concat(e.target.dataset.id)});
    }
    function doEntry(){
        setVisible(true)
        uploadFile(UploadFile).then(data=>{
            if(data['result']){
                var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
                let AllError=error.concat();
                let AllBody=Object.assign(body);
                var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-video'),true);
                AllError=AllError.concat(error);
                AllBody['video']=body;
                AllBody['deletes']=AttachedFile['deletes'].concat();
                AllBody['files']=[];
                if(AllError.length){
                    setAlert({type:'danger',msgs:AllError});
                    setVisible(false);
                    return;
                }else{
                    for(let i=0;i<data['UploadFile'].length;++i){
                        AllBody['files'].push(data['UploadFile'][i]['id']);
                    }
                    let send={
                        url:`v1/course/${course}/folder/${folder}/text`+(id=='new'?'':`/${id}`),
                        method:(id=='new'?'POST':'PUT'),
                        body:AllBody
                    }
                    Fetch(send).then(data=>{
                        if(data['result']){
                            setAlert({type:'success',msgs:["教材を"+(id=='new'?"登録":"変更")+"しました。"]});
                            setItem();
                            setTextItem();
                            Initialize();
                            navigate(`/course/${course}/folder/${folder}/text/`+(id=='new'?data['payloads']['TextId']:id));
                        }else{
                            setAlert({type:'danger',msgs:data['error']['description']})
                            setVisible(false)
                        }
                    }).catch(e=>{
                        console.log(e)
                        setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
                        setVisible(false)
                    })
                }
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    function doDelete(){
        let send={
            url:`v1/course/${course}/folder/${folder}/text/${id}`,
            method:'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:["教材を削除しました。"]});
                setItem();
                setTextItem();
                Initialize();
                navigate(`/course/${course}/folder/${folder}`);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            <Mode setMode={setMode} />
            {Item?<Crumb CurrentText={BCrumb?BCrumb:Item['FolderName']} items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}].concat(BCrumb?{index:2,href:`/course/${course}/folder/${folder}`,text:Item['FolderName']}:"")} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>{mode?"":
                <Link to={`/course/${course}/folder/${folder}/text/new/edit`} className="btn btn-success mb-3">新規教材</Link>}
                <Tab.Container id="folderText"  activeKey={Text}>
                    <Row>
                        <Col sm="auto">
                            <Nav variant="pills" className="flex-column">{Item['texts'].map((text,index)=>text['TextId']!='new' && 
                                <Nav.Item key={text['TextId']}>
                                    <Link to={`/course/${course}/folder/${folder}/text/${text['TextId']}`} data-id={text['TextId']} data-index={index} className={"nav-link"+(text['TextId']==Text?" active":"")} key={text['TextId']} onClick={changeText}>
                                    {text['TextName']}
                                    {(text['TextId']==Text && BCrumb==undefined)?setBCrumb(text['TextName']):""}
                                    </Link>
                                </Nav.Item>)}
                            </Nav>
                        </Col>
                        <Col sm>
                            <Tab.Content>{Item['texts'].map(text=>text['TextId']!='new' &&
                                <Tab.Pane eventKey={text['TextId']} key={text['TextId']}>
                                    <Card>
                                        <Card.Header className="w-100">
                                            <Row className="justify-content-between w-100">
                                                <Col sm="auto">
                                                    <Card.Title>
                                                        {text['TextName']}
                                                    </Card.Title>
                                                </Col>
                                                <Col sm="auto">{mode?
                                                    <Button variant="primary">
                                                        視聴完了
                                                    </Button>:""}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>{text['video']['link']?
                                            <div>
                                                <h4>動画</h4>
                                                <iframe src={text['video']['link']} className="w-100" style={{height:'600px'}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                                                <div className="text-end">
                                                    <a href={text['video']['link']} target="_blank">{text['video']['LinkName']?text['video']['LinkName']:text['video']['link']}</a>
                                                </div>
                                                <div>
                                                    {Escape(text['video']['explanation'])}
                                                </div>
                                                <hr />
                                            </div>:""}{text['files'].length?
                                            <div>
                                                <h4>添付ファイル</h4>
                                                <Row>
                                                    <Col sm="6">{(text['TextId']==Text && !isNaN(File))?
                                                        <Card>
                                                            <Card.Header>
                                                                <Card.Title>{text['files'][File]['FileName']}</Card.Title>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <iframe src={text['files'][File]['link']} className="iframe-file" />
                                                            </Card.Body>
                                                            <Card.Footer className="text-end">
                                                                <a href={text['files'][File]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>
                                                            </Card.Footer>
                                                        </Card>:""}
                                                    </Col>
                                                    <Col sm="6">
                                                        <ListGroup>{text['files'].map((file,index)=>
                                                            <ListGroup.Item action data-id={index} key={index} onClick={showFile}>
                                                                {file['FileName']}
                                                            </ListGroup.Item>)}
                                                        </ListGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>:""}
                                            <div>
                                                <h4>
                                                    教材情報
                                                </h4>
                                                <Table striped responsive>
                                                    <tbody>
                                                        <tr>
                                                            <th>
                                                                講義方法
                                                            </th>
                                                            <td>
                                                                {text['ClassMethod']}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                備考
                                                            </th>
                                                            <td>
                                                                {Escape(text['note'])}
                                                            </td>
                                                        </tr>{text['free'] &&
                                                        <tr>
                                                            <th>
                                                                体験教材
                                                            </th>
                                                            <td>
                                                                この教材は無料で受講できます。
                                                            </td>
                                                        </tr>}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card.Body>{mode?"":
                                        <Card.Footer>
                                            <Link to={`/course/${course}/folder/${folder}/text/${id}/edit`} className="btn btn-success">編集</Link>
                                        </Card.Footer>}
                                    </Card>{mode?"":
                                    <Card className="mt-2">
                                        <Card.Header>
                                            <Card.Title>進捗状況</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Table striped responsive>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            受講者名
                                                        </td>
                                                        <td>
                                                            進捗状況
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>{text['progress'].map(progress=>
                                                    <tr key={progress['StudentId']}>
                                                        <td>
                                                            {progress['UserName']}
                                                        </td>
                                                        <td>
                                                            {TextProgress(progress['watch'])}
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>}
                                </Tab.Pane>)}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                {(Edit && !mode && TextItem && Item['texts'][TextItem[Text]])?
                <Modal size="xl" show={Edit} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {Text=='new'?'新規教材':Item['texts'][TextItem[Text]]['TextName']}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
                            <Table striped responsive ref={Wrapper}>
                                <tbody>
                                    <tr>
                                        <th>
                                            教材名
                                        </th>
                                        <td>
                                            <FormControl type="text" className="js-data" placeholder="教材名" name="TextName" required defaultValue={Item['texts'][TextItem[Text]]['TextName']} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            講義方法
                                        </th>
                                        <td>
                                            <FormControl as="textarea" className="js-data" placeholder="講義方法" name="ClassMethod" defaultValue={Item['texts'][TextItem[Text]]['ClassMethod']} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            ビデオ
                                        </th>
                                        <td>
                                            <InputGroup className="mb-2">
                                                <InputGroup.Text>
                                                    動画URL
                                                </InputGroup.Text>
                                                <FormControl type="url" className="js-data-video" name="link" placeholder="動画URL" defaultValue={Item['texts'][TextItem[Text]]['video']['link']} />
                                            </InputGroup>
                                            <div>
                                                ※YouTubeの動画を埋め込む場合，<code>https://www.youtube.com/embed/YOUTUBEVIDEOID</code>で入力してください。<br />
                                                <p>
                                                    例)<br />
                                                    <code>https://www.youtube.com/watch?v=FwDo7MdaxhA</code>の場合，<code>https://www.youtube.com/embed/FwDo7MdaxhA</code>となります。
                                                </p>
                                            </div>
                                            <InputGroup className="mb-2">
                                                <InputGroup.Text>
                                                    動画名
                                                </InputGroup.Text>
                                                <FormControl type="text" className="js-data-video" name="LinkName" placeholder="動画名" defaultValue={Item['texts'][TextItem[Text]]['video']['LinkName']} />
                                            </InputGroup>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    動画の説明等
                                                </InputGroup.Text>
                                                <FormControl as="textarea" className="js-data-video" name="explanation" placeholder="動画の説明等" defaultValue={Item['texts'][TextItem[Text]]['video']['explanation']} />
                                            </InputGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            無料体験
                                        </th>
                                        <td>
                                            <FormSelect className="js-data w-auto" defaultValue={Item['texts'][TextItem[Text]]['free']} name="FreeText" data-type="boolean" required>
                                                <option value={true}>無料体験に該当</option>
                                                <option value={false}>無料体験に該当しない</option>
                                            </FormSelect>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            備考
                                        </th>
                                        <td>
                                            <FormControl as="textarea" name="note" className="js-data" defaultValue={Item['texts'][TextItem[Text]]['note']} placeholder="備考" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"130px"}}>
                                            公開設定
                                        </th>
                                        <td>
                                            <Row className="w-100">
                                                <Col sm="auto" className="mb-2">
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            公開方式
                                                        </InputGroup.Text>
                                                        <FormSelect className="js-data" name="PublicDate" defaultValue={Item['texts'][TextItem[Text]]['PublicDate']} onChange={changePublicDate} required>
                                                            <option value={0}>絶対日時</option>
                                                            <option value={1}>相対日時</option>
                                                        </FormSelect>
                                                    </InputGroup>
                                                </Col>
                                                <Col sm="auto" style={{display:PublicDate?'block':'none'}}>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            受講登録から
                                                        </InputGroup.Text>
                                                        <FormControl type="number" name="EntriedNumber" className="js-data" defaultValue={Item['texts'][TextItem[Text]]['EntriedNumber']} />
                                                        <InputGroup.Text>
                                                            日後
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                                <Col sm="auto" style={{display:PublicDate?'none':'block'}}>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            公開日
                                                        </InputGroup.Text>
                                                        <FormControl type="datetime-local" name="FromDate" className="js-data" defaultValue={ConvertToDatetime(Item['texts'][TextItem[Text]]['FromDate'])} />
                                                        <InputGroup.Text>
                                                            から
                                                        </InputGroup.Text>
                                                        <FormControl type="datetime-local" name="ToDate" className="js-data" defaultValue={ConvertToDatetime(Item['texts'][TextItem[Text]]['ToDate'])} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            添付ファイル
                                        </th>
                                        <td>{AttachedFile['files'].length?
                                            <div className="mb-2">
                                                <p className="m-0">
                                                    添付済みファイル
                                                </p>
                                                <ListGroup>{AttachedFile['files'].map((file,index)=>
                                                    <ListGroup.Item action data-id={file['FileId']} data-index={index} key={index}>
                                                        <InputGroup className="w-100">
                                                            <FormControl value={file['FileName']} />
                                                            <a href={file['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>{AttachedFile['deletes'].indexOf(file['FileId'])>-1?
                                                            <div className="btn btn-info">削除済み</div>:
                                                            <Button variant="danger" type="button" data-id={file['FileId']} data-index={index} onClick={removeAttachedFile}>削除</Button>}
                                                        </InputGroup>
                                                    </ListGroup.Item>)}
                                                </ListGroup>
                                            </div>:""}
                                            <div>
                                                <p className="m-0">
                                                    ファイルのアップロード
                                                </p>
                                                <UploadForm setUploadFile={setUploadFile} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="button" onClick={doEntry}>{Text=="new"?"登録":"変更"}</Button>{id!="new"?
                        <Button variant="danger" type="button" onClick={doDelete} className="ms-2">削除</Button>:""}
                    </Modal.Footer>
                </Modal>:""}
            </div>:""}
        </>
    );
}