import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Button, FormCheck, Table } from "react-bootstrap";

export default function Cancellation(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Student,setStudent]=useState([]);

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        let send={
            url:`v1/course/${course}/cancellation`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function changeStudent(e){
        let newStudent=Student.concat();
        let idx=newStudent.indexOf(e.target.value)
        if(idx>-1){
            newStudent.splice(idx,1);
        }else{
            newStudent=newStudent.concat(e.target.value)
        }
        setStudent(newStudent);
    }
    function doCancel(){
        setVisible(true)
        let send={
            url:`v1/course/${course}/cancellation`,
            method:'POST',
            body:Student
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:["解約しました。"]})
                setItem();
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText="解約申請" items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <td>
                                申請者名
                            </td>
                            <td>
                                状態
                            </td>
                        </tr>
                    </thead>
                    <tbody>{Item['cancellations'].map((student,index)=>
                        <tr>
                            <td>
                                {student['UserName']}
                            </td>
                            <td>{student['condition']?"解約済み":
                                <FormCheck type="switch" id={student['CancellationId']} value={student['CancellationId']} onClick={changeStudent} checked={Student.indexOf(student['CancellationId'])>-1?true:false} label="解約" />}
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={doCancel}>変更</Button>
            </div>:""}
        </>
    );
}