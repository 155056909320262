import { Form } from "react-bootstrap";
import Store, { ChangeMode } from "../../controller/Store";
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";

export default function Mode({setMode}){
    let mode=useSelector((state)=>state['mode']);
    const dispatch=useDispatch();
    const [CurrentMode,setCurrentMode]=useState(Store.getState()['mode']);
    function changeMode(){
        mode=!mode;
        dispatch(ChangeMode(mode))
        setCurrentMode(mode);
    }
    useEffect(()=>{
        setMode(CurrentMode)
    },[CurrentMode])
    return (
        <div>
            <div className={"position-absolute w-100 start-0 "+(CurrentMode?"bg-warning":"bg-light")} style={{top:"48px"}}>
                <div className="position-relative text-center" style={{height:"24px"}}>
                    {CurrentMode?"受講者モード":""}
                    <div className="position-absolute top-0 end-0">
                        <Form.Check type="switch" id="ModeSwitch" label="受講者モード" checked={CurrentMode} onChange={changeMode} />
                    </div>
                </div>
            </div>
            <div style={{height:"30px"}}></div>
        </div>
    );
}   /*
const mapStateToProps=(state)=>{
    return state;
};
export default connect(mapStateToProps)(Mode)*/