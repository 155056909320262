import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, FormSelect, InputGroup, ListGroup, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import { getCourses, getUser, setCourses } from "../..";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import { ConvertToDate, ConvertToDatetime, createKey } from "../../controller/keys";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";
import Upload from "../../controller/Upload";
import UploadForm, { uploadFile } from "../../controller/UploadForm";

export default function CourseEntry(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Keywords,setKeywords]=useState([]);
    const [Needs,setNeeds]=useState([]);
    const [Plans,setPlans]=useState([]);
    const [Course,setCourse]=useState(getCourses());
    const [AccountNumber,setAccountNumber]=useState(1);
    const [LINE,setLINE]=useState();
    const [Account,setAccount]=useState();
    const [Price,setPrice]=useState([0,0]);//[setup,monthly]

    const Wrapper=useRef();
    const Contract=useRef();
    const ContractName=useRef();

    const User=getUser();

    useEffect(()=>{
        Initialize();
        let send={
            'url':'v1/teacher/line',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setLINE(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
        }).catch(error=>console.log(error));
        send['url']='v1/teacher/account';
        Fetch(send).then(data=>{
            if(data['result']){
                setAccount(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
        }).catch(error=>console.log(error));
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/syllabus/${course}`,
            method:'GET'
        }
        if(course!='new'){
            Fetch(send).then(data=>{
                if(data['result']){
                    setItem(data['payloads']);
                    setKeywords(data['payloads']['keywords'])
                    setPlans(data['payloads']['plans'])
                    setNeeds(data['payloads']['needs'])
                    setPrice([data['SetupPrice'],data['MonthlyPrice']])
                    setCourses(data['payloads']['summary']);
                    setCourse(data['payloads']['summary']);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }else{
            send['url']='v1/course';
            Fetch(send).then(data=>{
                if(data['result']){
                    setCourses(data['payloads']['summary'])
                    setCourse(data['payloads']['summary'])
                    setItem({
                        "summary":data['payloads']['summary'],
                        "CourseName": "",
                        "UserId":User['UserId'],
                        "term": [
                            "",
                            ""
                        ],
                        "teachers": [
                            {
                                TeacherId:createKey(),
                                "UserId":User['UserId'],
                                UserName:User['UserName'],
                                link:"",
                                email:"",
                                tel:"",
                                line:""
                            }
                        ],
                        "CourseMethod": "",
                        "CourseTarget": "",
                        "CourseLevel": "",
                        "keywords": [""],
                        "MaxStudent": "",
                        "SetupPrice": "",
                        "MonthlyPrice": "",
                        "AccountNumber": "",
                        "free": "",
                        "goal": "",
                        "plans": [""],
                        "needs": [""],
                        "attention": "",
                        "LineId": "",
                        "AccountId":"",
                        "contract": []
                    })
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false)
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
                setVisible(false)
            })
        }
    }
    function changePrice(e){
        let newPrice=Price.concat();
        newPrice[e.target.dataset.index]=e.target.value;
        setPrice(newPrice);
    }
    function changeKeyword(e){
        let newKeyword=Keywords.concat();
        newKeyword[e.target.dataset.index]=e.target.value;
        setKeywords(newKeyword);
    }
    function changeNeed(e){
        let newNeed=Needs.concat();
        newNeed[e.target.dataset.index]=e.target.value;
        setNeeds(newNeed)
    }
    function changePlan(e){
        let newPlan=Plans.concat();
        newPlan[e.target.dataset.index]=e.target.value;
        setPlans(newPlan)
    }
    function addKeyword(){
        setKeywords(Keywords.concat(""));
    }
    function addNeed(){
        setNeeds(Needs.concat(""));
    }
    function addPlan(){
        setPlans(Plans.concat(""));
    }
    function removeKeyword(e){
        let newKeyword=Keywords.concat();
        newKeyword.splice(e.target.dataset.index,1)
        setKeywords(newKeyword);
    }
    function removeNeed(e){
        let newNeed=Needs.concat();
        newNeed.splice(e.target.dataset.index,1)
        setNeeds(newNeed)
    }
    function removePlan(e){
        let newPlan=Plans.concat();
        newPlan.splice(e.target.dataset.index,1);
        setPlans(newPlan)
    }
    function changeContract(e){
        ContractName.current.value=e.target.files[0]['name'];
        e.target.dataset.name=e.target.files[0]['name'];
        e.target.dataset.id=""
    }
    function changeContractName(e){
        Contract.current.dataset.name=e.target.value;
    }
    function changeAccountNumber(e){
        setAccountNumber(Number(e.target.value));
    }
    function doEntry(){
        setVisible(true);
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data'),true);
        let AllError=error.concat();
        let AllBody=Object.assign(body);
        let term=Wrapper.current.getElementsByClassName('js-data-term');
        AllBody['term']=[term[0].value,term[1].value];
        AllBody['keywords']=Keywords;
        AllBody['plans']=Plans;
        AllBody['needs']=Needs;
        var {error,body}=SendJson(Wrapper.current.getElementsByClassName('js-data-teacher'),true);
        AllBody['teacher']=body;
        AllError=AllError.concat(error);
        if(course=='new' && ContractName.current.value==""){
            AllError.push("契約書名を入力してください。")
        }
        if(AllError.length){
            setAlert({type:"danger",msgs:AllError})
            setVisible(false);
            return;
        }else{
            if(Contract.current.dataset.id || (course!='new' && Contract.current.files.length==0)){
                AllBody['contract']=Contract.current.dataset.id;
                doSubmit(AllBody);
            }else{
                let fd=new FormData();
                fd.append(`file[0]`,Contract.current.files[0]);
                fd.append(`FileName[0]`,Contract.current.dataset.name['name']);
                fd.append(`FileId[0]`,0);
                Upload(fd).then(data=>{
                    if(data['result']){
                        AllBody['contract']=data['payloads']['uploaded'][0];
                        Contract.current.dataset.id=data['payloads']['uploaded'][0];
                        doSubmit(AllBody);
                    }else{
                        setAlert({type:"danger",msgs:data['error']['description']})
                        setVisible(false)
                    }
                }).catch(e=>{
                    console.log(e)
                    setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
                    setVisible(false)
                })
            }
        }
    }
    function doSubmit(body){
        let send={
            url:`v1/course`+(course=='new'?"":`/${course}`),
            method:(course=='new'?"POST":"PUT"),
            body:body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["コースを"+(course=="new"?'登録':"変更")+"しました。"]})
                if(course!='new'){
                    Initialize();
                }else{
                    setVisible(false);
                }
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    function doDelete(){
        setVisible(true);
        let send={
            url:`v1/course/${course}`,
            method:"DELETE",
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem();
                setAlert({type:'success',msgs:["コースを削除しました。"]})
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={course=='new'?"新規コース":Item['CourseName']+"編集"} items={[{index:0,href:'/',text:'ホーム'}].concat(course!='new' && {index:1,href:`/course/${course}`,text:Item['CourseName']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{(Item && LINE && Account)?
            <div>
                <div className="mb-3">
                    <Table striped responsive ref={Wrapper}>
                        <tbody>
                            <tr>
                                <th>
                                    コース名
                                </th>
                                <td>
                                    <FormControl type="text" name="CourseName" className="js-data" placeholder="コース名" maxLength={250} defaultValue={Item['CourseName']} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    開講期間
                                </th>
                                <td>
                                    <InputGroup>
                                        <FormControl type="date" name="CourseTerm[0]" className="js-data-term" defaultValue={ConvertToDate(Item['term'][0])} required />
                                        <InputGroup.Text>から</InputGroup.Text>
                                        <FormControl type="date" name="CourseTerm[1]" className="js-data-term" defaultValue={ConvertToDate(Item['term'][1])} required />
                                        <InputGroup.Text>まで</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    講師
                                </th>
                                <td>
                                    <ListGroup>{Item['teachers'].map(teacher=>
                                        <ListGroup.Item key={teacher['TeacherId']}>{teacher['UserId']==User['UserId']?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            {teacher['UserName']}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            Webページ
                                                        </th>
                                                        <td>
                                                            <FormControl type="url" name="link" className="js-data-teacher" placeholder="Webページ" defaultValue={teacher['link']} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            メールアドレス
                                                        </th>
                                                        <td>
                                                            <FormControl type="email" name="email" className="js-data-teacher" placeholder="メールアドレス" defaultValue={teacher['email']} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            電話番号
                                                        </th>
                                                        <td>
                                                            <FormControl type="tel" name="tel" className="js-data-teacher" placeholder="電話番号" defaultValue={teacher['tel']} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            公式LINE登録URL
                                                        </th>
                                                        <td>
                                                            <FormControl type="url" name="line" className="js-data-teacher" placeholder="公式LINE登録URL" defaultValue={teacher['line']} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>:
                                            teacher['UserName']}
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    講義方法
                                </th>
                                <td>
                                    <FormControl as="textarea" name="CourseMethod" className="js-data" placeholder="講義方法" maxLength={250} defaultValue={Item['CourseMethod']} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    受講レベル
                                </th>
                                <td>
                                    <FormControl type="text" name="CourseLevel" className="js-data" list="CourseLevelOptions" placeholder="受講レベル" maxLength={250} defaultValue={Item['CourseLevel']} />
                                    <datalist id="CourseLevelOptions">
                                        <option value="指定なし" />
                                        <option value="初心者" />
                                        <option value="初級者" />
                                        <option value="中級者" />
                                        <option value="上級者" />
                                        <option value="セミプロ" />
                                        <option value="プロ" />
                                    </datalist>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    対象者
                                </th>
                                <td>
                                    <FormControl type="text" name="CourseTarget" className="js-data" placeholder="対象者" maxLength={250} defaultValue={Item['CourseTarget']} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    キーワード
                                </th>
                                <td>
                                    <Button variant="success" type="button" onClick={addKeyword} className="mb-2">追加</Button>
                                    <ListGroup>{Keywords.map((keyword,index)=>
                                        <ListGroup.Item key={index}>
                                            <InputGroup>
                                                <FormControl type="text" data-index={index} placeholder="キーワード" value={keyword} onChange={changeKeyword} />
                                                <Button variant="danger" data-index={index} onClick={removeKeyword}>削除</Button>
                                            </InputGroup>
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    最大受講者数
                                </th>
                                <td>
                                    <InputGroup>
                                        <FormControl type="number" name="MaxStudent" className="js-data" placeholder="最大受講者数" min={0} max={Course['MaxStudent']} defaultValue={Item['MaxStudent']} required />
                                        <InputGroup.Text>名</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    初期費用
                                </th>
                                <td>
                                    <InputGroup>
                                        <FormControl type="number" name="SetupPrice" className="js-data" placeholder="初期費用" min={0} defaultValue={Item['SetupPrice']} data-type="numeric" data-index={0} readOnly={course=='new'?false:true} onChange={changePrice} />
                                        <InputGroup.Text>円</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    月額費用
                                </th>
                                <td>
                                    <InputGroup>
                                        <FormControl type="number" name="MonthlyPrice" className="js-data" placeholder="月額費用" min={0} defaultValue={Item['MonthlyPrice']} data-type="numeric" readOnly={course=='new'?false:true} data-index={1} onChange={changePrice} />
                                        <InputGroup.Text>円</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    契約月数
                                </th>
                                <td>
                                    <Row className="w-100">
                                        <Col sm="auto">
                                            <InputGroup>
                                                <InputGroup.Text>契約月数</InputGroup.Text>
                                                <FormSelect name="AccountNumberType" className="js-data" defaultValue={Item['AccountNumberType']} data-type="numeric" disabled={course=='new'?false:true} onChange={changeAccountNumber} required>
                                                    <option value={1}>有限回</option>
                                                    <option value={0}>無限回</option>
                                                </FormSelect>
                                            </InputGroup>
                                        </Col>
                                        <Col style={{display:AccountNumber?'block':"none"}}>
                                            <InputGroup>
                                                <FormControl type="number" name="AccountNumber" className="js-data" placeholder="契約月数" min={1} defaultValue={Item['AccountNumber']} data-type="numeric" readOnly={course=='new'?false:true} />
                                                <InputGroup.Text>回</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    無料期間
                                </th>
                                <td>
                                    <FormSelect name="free" className="js-data" defaultValue={Item['free']} disabled={course=='new'?false:true} data-type="numeric">
                                        <option value={0}>無料期間なし</option>
                                        <option value={1}>1カ月無料</option>
                                    </FormSelect>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    講義目標
                                </th>
                                <td>
                                    <FormControl as="textarea" name="goal" className="js-data" placeholder="講義目標" maxLength={250} defaultValue={Item['goal']} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    講義計画
                                </th>
                                <td>
                                <Button variant="success" type="button" onClick={addPlan} className="mb-2">追加</Button>
                                    <ListGroup as="ol">{Plans.map((plan,index)=>
                                        <ListGroup.Item key={index}>
                                            <InputGroup>
                                                <FormControl type="text" placeholder="講義計画" value={plan} data-index={index} onChange={changePlan} />
                                                <Button variant="danger" onClick={removePlan} data-index={index}>削除</Button>
                                            </InputGroup>
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    持ち物等
                                </th>
                                <td>
                                    <Button variant="success" type="button" onClick={addNeed} className="mb-2">追加</Button>
                                    <ListGroup>{Needs.map((need,index)=>
                                        <ListGroup.Item key={index}>
                                            <InputGroup>
                                                <FormControl type="text" placeholder="持ち物等" value={need} data-index={index} onChange={changeNeed} />
                                                <Button variant="danger" onClick={removeNeed} data-index={index}>削除</Button>
                                            </InputGroup>
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    受講上の注意
                                </th>
                                <td>
                                    <FormControl as="textarea" name="attention" className="js-data" placeholder="受講上の注意" maxLength={250} defaultValue={Item['attention']} />
                                </td>
                            </tr>{Item['UserId']==User['UserId']?
                            <>
                            <tr>
                                <th>
                                    公式LINE連携
                                </th>
                                <td>
                                    <FormSelect name="LineId" className="js-data" defaultValue={Item['LineId']}>
                                        <option value={0}>連携しない</option>{LINE.map(line=>
                                        <option value={line['LineId']} key={line['LineId']}>{line['LineName']}</option>)}
                                    </FormSelect>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    決済連携
                                </th>
                                <td>
                                    <FormSelect name="AccountId" className="js-data" defaultValue={Item['AccountId']}>
                                        {(Price[0]==0 && Price[1]==0)?<option value={0}>連携しない</option>:""}{Account.map(account=>
                                        <option value={account['AccountId']} key={account['AccountId']}>{account['AccountName']}</option>)}
                                    </FormSelect>
                                </td>
                            </tr>
                            </>:""}
                            <tr>
                                <th>
                                    契約書
                                </th>
                                <td>{course=='new'?
                                <InputGroup>
                                    <FormControl type="file" data-name="" data-id="" accept="application/pdf" onChange={changeContract} ref={Contract} required />
                                    <InputGroup.Text>契約書名</InputGroup.Text>
                                    <FormControl type="text" placeholder="契約書名" required ref={ContractName} onChange={changeContractName} />
                                </InputGroup>:
                                <Tabs defaultActiveKey={"current"}>
                                    <Tab eventKey={'current'} title="現在の契約書" className="border border-radius p-2" style={{backgroundColor:"#fff"}}>
                                        <a href={Item['contract']['link']} target="_blank">{Item['contract']['name']}</a>
                                    </Tab>
                                    <Tab eventKey={'new'} title="新規の契約書" className="border border-radius p-2" style={{backgroundColor:"#fff"}}>
                                        <FormControl type="file" data-name="" data-id={Item['contract']['FileId']} accept="application/pdf" onChange={changeContract} ref={Contract} required />
                                        <InputGroup>
                                            <InputGroup.Text>契約書名</InputGroup.Text>
                                            <FormControl type="text" placeholder="契約書名" required ref={ContractName} onChange={changeContractName} />
                                        </InputGroup>
                                    </Tab>
                                </Tabs>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    検索結果への表示
                                </th>
                                <td>
                                    <FormSelect name="display" className="js-data" defaultValue={Item['display']} data-type="numeric">
                                        <option value={1}>表示する</option>
                                        <option value={0}>表示しない</option>
                                    </FormSelect>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="mt-2">
                        <Button variant="primary" type="button" onClick={doEntry}>
                            {course=='new'?"登録":"変更"}
                        </Button>{/*course=='new'?"":
                        <Button variant="danger" type="button" onClick={doDelete} className="ms-2">
                            削除
            </Button>*/}
                    </div>
                </div>
            </div>:""}
        </>
    );
}