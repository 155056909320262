import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import SendJson from "../../controller/SendJson";
import { Button, Col, FormCheck, FormSelect, InputGroup, Modal, Row, Tab, Table } from "react-bootstrap";
import { ConvertToDisplayDate, number_format, PaymentCondition, StudentCondition } from "../../controller/keys";

export default function Student(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Student,setStudent]=useState();
    const [Payment,setPayment]=useState([]);

    const CourseVisible=useRef();
    const PaymentWrapper=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/student`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function showStudent(e){
        e.preventDefault();
        setStudent(Number(e.target.dataset.index));
    }
    function closeModal(){
        setStudent();
    }
    function changePayment(e){
        let newPayment=Payment.concat();
        let idx=newPayment.indexOf(e.target.value);
        if(idx>-1){
            newPayment.splice(idx,1);
        }else{
            newPayment=newPayment.concat(e.target.value)
        }
        setPayment(newPayment);
    }
    function doGraduate(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/student/${Item['students'][Student]['StudentId']}`,
            method:'PUT',
            body:{
                condition:2
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:[Item['students'][Student]['UserName']+'を修了に変更しました。']})
                closeModal();
                setItem();
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
                setVisible(false);
            }
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
        })
    }
    function doVisible(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/student/${Item['students'][Student]['StudentId']}`,
            method:'PUT',
            body:{
                visible:CourseVisible.current.value=='true'?true:false
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:[Item['students'][Student]['UserName']+'のコース内容表示を変更しました。']})
                closeModal();
                setItem();
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
                setVisible(false);
            }
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
        })
    }
    function doPayment(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/student/${Item['students'][Student]['StudentId']}`,
            method:'PUT',
            body:{
                unpay:[]
            }
        }
        for(let i=0;i<Payment.length;++i){
            send['body']['unpay'].push({
                PaymentId:Payment[i],
                condition:0
            });
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:[Item['students'][Student]['UserName']+'の決済内容を変更しました。']})
                closeModal();
                setItem();
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
                setVisible(false);
            }
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={"受講者情報"} items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <td>
                                受講者名
                            </td>
                            <td>
                                未払い
                            </td>
                            <td>
                                参加日
                            </td>
                        </tr>
                    </thead>
                    <tbody>{Item['students'].map((student,index)=>
                        <tr key={index}>
                            <td>
                                <a href="#" data-index={index} onClick={showStudent}>
                                    {student['UserName']}
                                </a>
                            </td>
                            <td>
                                <ul>{student['unpay'].map((unpay,idx)=>
                                    <li key={idx}>
                                        [{unpay['PaymentTitle']}]{number_format(unpay['price'])}円
                                    </li>)}
                                </ul>
                            </td>
                            <td>
                                {ConvertToDisplayDate(student['FromDate'])}[{StudentCondition(student['condition'])}]
                            </td>
                        </tr>)}
                    </tbody>
                </Table>{!isNaN(Student)?
                <Modal size="xl" show={true} onHide={closeModal} scrollable centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{Item['students'][Student]['UserName']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive>
                            <tbody>
                                <tr>
                                    <th>
                                        参加日
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(Item['students'][Student]['FromDate'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        状態
                                    </th>
                                    <td>
                                        {Item['students'][Student]['condition']?
                                        <Row className="w-100">
                                            <Col sm="auto">
                                                {ConvertToDisplayDate(Item['students'][Student]['ToDate'])}に{StudentCondition(Item['students'][Student]['condition'])}
                                            </Col>
                                            <Col sm="auto">
                                                <InputGroup>
                                                    <InputGroup.Text>コース内容を</InputGroup.Text>
                                                    <FormSelect defaultValue={Item['students'][Student]['visible']} ref={CourseVisible}>
                                                        <option value={true}>表示する</option>
                                                        <option value={false}>表示しない</option>
                                                    </FormSelect>
                                                    <Button variant="secondary" type="button" onClick={doVisible}>変更</Button>
                                                </InputGroup>
                                            </Col>
                                        </Row>:
                                        <Row className="w-100">
                                            <Col sm="auto">
                                                {StudentCondition(Item['students'][Student]['condition'])}
                                            </Col>
                                            <Col sm="auto">
                                                <Button type="button" variant="secondary" onClick={doGraduate}>修了</Button>
                                            </Col>
                                        </Row>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Body>
                        <Table responsive striped ref={PaymentWrapper}>
                            <thead>
                                <tr>
                                    <td>
                                        タイトル
                                    </td>
                                    <td>
                                        金額
                                    </td>
                                    <td>
                                        状態
                                    </td>
                                </tr>
                            </thead>
                            <tbody>{Item['students'][Student]['payments'].map((payment,index)=>
                                <tr key={index}>
                                    <td>
                                        {payment['PaymentTitle']}
                                    </td>
                                    <td>
                                        {number_format(payment['price'])}円
                                    </td>
                                    <td>
                                        {PaymentCondition(payment['condition'])}
                                        {payment['condition']?
                                        <FormCheck type="switch" id={`payment_check_${index}_${payment['PaymentId']}`} className="js-data-payment" value={payment['PaymentId']} label="決済済み" onChange={changePayment} checked={Payment.indexOf(payment['PaymentId'])>-1?true:false} />:""}
                                    </td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <Button variant="primary" type="button" onClick={doPayment}>決済内容変更</Button>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Modal.Body>
                </Modal>:""}
            </div>:""}
        </>
    );
}