import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser, setCourses } from "..";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import CardList from "../controller/CardList";

export default function Home(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let user=getUser();
        if(user['UserType']<2){
            let send={
                url:'v1/teacher',
                method:'GET'
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setCourses(data['payloads']['courses']['summary'])
                    let tmp=data['payloads']['courses']['courses'];
                    let courses=[];
                    for(let i=0;i<tmp.length;++i){
                        courses.push({
                            index:i,
                            href:'/course/'+tmp[i]['CourseId'],
                            text:tmp[i]['CourseName']
                        });
                    }
                    tmp=data['payloads']['accounts'];
                    let accounts=[];
                    for(let i=0;i<tmp.length;++i){
                        accounts.push({
                            index:i,
                            href:'/account/'+tmp[i]['AccountId'],
                            text:tmp[i]['AccountName']
                        });
                    }
                    tmp=data['payloads']['lines'];
                    let lines=[];
                    for(let i=0;i<tmp.length;++i){
                        lines.push({
                            index:i,
                            href:'/line/'+tmp[i]['uid'],
                            text:tmp[i]['LineName']
                        });
                    }
                    setItem(Object.assign(
                        data['payloads'],
                        {
                            CourseList:courses,
                            AccountList:accounts,
                            LineList:lines
                        }
                    ));
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']})
                }
                setVisible(false);
            }).catch(e=>{
                console.log(e)
                setVisible(false);
                setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            })
        }
    },[])
    return (
        <div className="mt-3">
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="開講中コース" items={Item['CourseList']} />{Item['courses']['summary']['HavingCourse']<Item['courses']['summary']['MaxCourse']?
                        <Link to="/course/new/edit">コース開設</Link>:"開講コースの上限に達しています"
                    }
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="公式LINE" items={Item['LineList']} />
                    <Link to="/line/new">LINE連携</Link>
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="決済" items={Item['AccountList']} />
                    <Link to="/account/new">決済連携</Link>
                </div>
            </div>:""}
        </div>
    );
}