import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Button, FormControl, InputGroup, ListGroup } from "react-bootstrap";
import Upload from "../../controller/Upload";

export default function Folder(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Folder,setFolder]=useState([]);

    useEffect(()=>{
        Initialize()
    },[])
    function Initialize(){
        setVisible(true)
        let send={
            url:`v1/course/${course}/folder`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads'])
                setFolder(data['payloads']['folders']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function changeName(e){
        let newFolder=Folder.concat();
        newFolder[e.target.dataset.index]['FolderName']=e.target.value;
        setFolder(newFolder)
    }
    function changeFile(e){
        let newFolder=Folder.concat();
        if(e.target.files.length){
            newFolder[e.target.dataset.index]['icon']=window.URL.createObjectURL(e.target.files[0]);
        }else{
            newFolder[e.target.dataset.index]['icon']=e.target.dataset.src;
        }
        setFolder(newFolder)
    }
    function up(e){
        let newFolder=Folder.concat();
        let idx=Number(e.target.dataset.index);
        newFolder.splice(idx-1,2,newFolder[idx],newFolder[idx-1]);
        setFolder(newFolder);
    }
    function down(e){
        let newFolder=Folder.concat();
        let idx=Number(e.target.dataset.index);
        newFolder.splice(idx,2,newFolder[idx+1],newFolder[idx]);
        setFolder(newFolder);
    }
    function copy(e){
        let newFolder=Folder.concat();
        let addFolder=Object.assign({},Folder[e.target.dataset.index]);
        addFolder['FolderId']='new';
        addFolder['FolderName']+=" - copy";
        addFolder['icon']="https://file.canteach.jp/file/0";
        newFolder.splice(1+Number(e.target.dataset.index),0,addFolder)
        setFolder(newFolder);
    }
    function remove(e){
        let newFolder=Folder.concat();
        newFolder[e.target.dataset.index]['condition']=false;
        setFolder(newFolder);
    }
    function add(){
        setFolder(Folder.concat({
            FolderId:"new",
            FolderName:"新しいフォルダー",
            icon:"https://file.canteach.jp/file/0",
            file:{
                FileId:"",
                FileName:"",
                link:"https://file.canteach.jp/file/0",
                type:"image/*"
            },
            parent:0,
            condition:true
        }));
    }
    function doEntry(){
        setVisible(true)
        let icons=document.getElementsByClassName('js-data-icon');
        let fd=new FormData();
        let cnt=0;
        for(let i=0;i<icons.length;++i){
            if(icons[i].files[0]){
                fd.append(`file[${i}]`,icons[i].files[0]);
                fd.append(`FileName[${i}]`,Folder[i]['FolderName']+" フォルダーサムネイル");
                fd.append(`FileId[${i}]`,icons[i].dataset.index);
                ++cnt;
            }
        }
        if(cnt){
            Upload(fd).then(data=>{
                if(data['result']){
                    let newFolder=Folder.concat();
                    Object.keys(data['payloads']['FileObject']).forEach(key=>{
                        newFolder[key]['icon']=data['payloads']['FileObject'][key]['link'];
                        newFolder[key]['file']=data['payloads']['FileObject'][key];
                        if(icons[key]){
                            icons[key].value="";
                        }
                    });
                    setFolder(newFolder);
                    doSubmit(newFolder);
                }else{
                    setAlert({type:'danger',msgs:data['error']['description']});
                    setVisible(false);
                }
            }).catch(e=>{
                console.log(e)
                setAlert({type:'danger',msgs:['通信エラーが発生しました。']});
                setVisible(false)
            })
        }else{
            doSubmit(Folder);
        }
    }
    function doSubmit(folder){
        let send={
            url:`v1/course/${course}/folder`,
            method:'POST',
            body:[]
        }
        for(let i=0;i<folder.length;++i){
            send['body'].push({
                FolderId:folder[i]['FolderId'],
                FolderName:folder[i]['FolderName'],
                icon:folder[i]['file']['FileId'],
                number:i,
                condition:folder[i]['condition']
            })
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'info',msgs:["フォルダーを登録しました"]})
                setItem();
                Initialize();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']});
                setVisible(false);
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']});
            setVisible(false)
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText="フォルダー管理" items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Folder?
            <div>
                <div className="mb-3">
                    <Button variant="success" onClick={add} type="button">新しいフォルダー</Button>
                </div>
                <ListGroup>{Folder.map((folder,index)=>folder['condition']?
                    <ListGroup.Item action key={index}>
                        <InputGroup>
                            <InputGroup.Text>
                                <img src={folder['icon']} className="files__icon js-icon" />
                            </InputGroup.Text>
                            <FormControl type="text" placeholder="フォルダー名" value={folder['FolderName']} onChange={changeName} data-index={index} required />
                            <FormControl type="file" onChange={changeFile} className="js-data-icon" accept="image/*" data-src={folder['file']['link']} data-index={index} />
                            <Button type="button" variant="secondary" data-index={index} className="fas fa-arrow-up" onClick={up} disabled={!index}></Button>
                            <Button type="button" variant="secondary" data-index={index} className="fas fa-arrow-down" onClick={down} disabled={index+1==Folder.length}></Button>
                            <Button type="button" variant="success" data-index={index} className="fas fa-copy" onClick={copy}></Button>
                            <Button type="button" variant="danger" data-index={index} className="fas fa-trash-alt" onClick={remove}></Button>
                        </InputGroup>
                    </ListGroup.Item>:"")}
                </ListGroup>{Folder.length?
                <div className="mt-3">
                    <Button variant="primary" type="button" onClick={doEntry}>登録</Button>
                </div>:""}
            </div>:""}
        </>
    );
}