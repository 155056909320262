import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Accordion, Modal, Table } from "react-bootstrap";
import { number_format } from "../../controller/keys";

export default function Grade(){
    const {course}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Student,setStudent]=useState();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        let send={
            url:`v1/course/${course}/grade`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    function showStudent(e){
        e.preventDefault();
        setStudent(Number(e.target.dataset.index));
    }
    function closeModal(){
        setStudent()
    }
    return (
        <>
            {Item?<Crumb CurrentText="受講者成績" items={[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${course}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <td>
                                受講者名
                            </td>
                            <td>
                                課題成績
                            </td>
                            <td>
                                テスト成績
                            </td>
                            <td>
                                合計
                            </td>
                        </tr>
                    </thead>
                    <tbody>{Item['grades'].map((grade,index)=>
                        <tr key={index}>
                            <td>
                                <a href="" data-index={index} onClick={showStudent}>
                                    {grade['UserName']}
                                </a>
                            </td>
                            <td>
                                {number_format(grade['summary']['reports']['score'])}/{number_format(grade['summary']['reports']['total'])}点
                            </td>
                            <td>
                                {number_format(grade['summary']['examinations']['score'])}/{number_format(grade['summary']['examinations']['total'])}点
                            </td>
                            <td>
                                {number_format(grade['summary']['total']['score'])}/{number_format(grade['summary']['total']['total'])}点
                            </td>
                        </tr>)}
                    </tbody>
                </Table>{!isNaN(Student)?
                <Modal size="xl" show={true} onHide={closeModal} scrollable centered fullscreen>
                    <Modal.Header closeButton>
                        <Modal.Title>{Item['grades'][Student]['UserName']}成績詳細</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion defaultActiveKey="2">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    課題成績
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table striped responsive>
                                        <thead>
                                            <tr>
                                                <td>
                                                    課題名
                                                </td>
                                                <td>
                                                    得点
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>{Item['grades'][Student]['reports'].map((grade,index)=>
                                            <tr key={index}>
                                                <td>
                                                    {grade['ReportTitle']}
                                                </td>
                                                <td>
                                                    {number_format(grade['score'])}/{number_format(grade['total'])}点
                                                </td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>
                                                    合計
                                                </th>
                                                <td>
                                                    {number_format(Item['grades'][Student]['summary']['reports']['score'])}/{number_format(Item['grades'][Student]['summary']['reports']['total'])}点
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    テスト成績
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table striped responsive>
                                        <thead>
                                            <tr>
                                                <td>
                                                    課題名
                                                </td>
                                                <td>
                                                    得点
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>{Item['grades'][Student]['examinations'].map((grade,index)=>
                                            <tr key={index}>
                                                <td>
                                                    {grade['ExaminationTitle']}
                                                </td>
                                                <td>
                                                    {number_format(grade['score'])}/{number_format(grade['total'])}点
                                                </td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>
                                                    合計
                                                </th>
                                                <td>
                                                    {number_format(Item['grades'][Student]['summary']['examinations']['score'])}/{number_format(Item['grades'][Student]['summary']['examinations']['total'])}点
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                </Modal>:""}
            </div>:""}
        </>
    );
}