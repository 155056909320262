import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AccountEdit from './view/account/edit';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Cancellation from './view/course/cancellation';
import Detail from './view/course/details';
import Edit from './view/course/edit';
import Examination from './view/course/examination';
import ExaminationEntry from './view/course/ExaminationEntry';
import Folder from './view/course/folder';
import Grade from './view/course/grade';
import Info from './view/course/info';
import InfoEntry from './view/course/InfoEntry';
import Report from './view/course/report';
import ReportEntry from './view/course/ReportEntry';
import Step from './view/course/step';
import Student from './view/course/student';
import Teacher from './view/course/teacher';
import Text from './view/course/text';
import Home from './view/Home';
import LineEdit from './view/line/edit';

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main pt-0' role="main">
				<div className='container h-100 mt-0'>
					<div className='row h-100 mt-0'>
						<div className='col-12 h-100 mt-0'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
								</Route>
								<Route path="/course">
									<Route path=':course'>
										<Route index element={<Detail />} />
										<Route path='edit' element={<Edit />} />
										<Route path='folder'>
											<Route index element={<Folder />} />
											<Route path=':folder'>
												<Route index  element={<Text />} />
												<Route path='text/:id' element={<Text />} />
												<Route path='text/:id/edit' element={<Text edit={true} />} />
											</Route>
										</Route>
										<Route path='contact'>
											<Route index element={<InfoEntry />} />
											<Route path=':id' element={<Info />} />
										</Route>
										<Route path='report/:id'>
											<Route index element={<Report />} />
											<Route path='edit' element={<ReportEntry />} />
										</Route>
										<Route path='examination/:id'>
											<Route index element={<Examination />} />
											<Route path='edit' element={<ExaminationEntry />} />
										</Route>
										<Route path='step/:id' element={<Step />} />
										<Route path='student' element={<Student />} />
										<Route path='grade' element={<Grade />} />
										<Route path='cancellation' element={<Cancellation />} />
										<Route path="teacher" element={<Teacher />} />
									</Route>
								</Route>
								<Route path="/account">
									<Route path=':id'>
										<Route index element={<AccountEdit />} />
									</Route>
								</Route>
								<Route path='line'>
									<Route path=':id'>
										<Route index element={<LineEdit />} />
									</Route>
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
